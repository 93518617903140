import { Injectable } from '@angular/core';

import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

import { BehaviorSubject } from 'rxjs';
import { IPusherEvent, IPusherMessage, PusherChannels, PusherEvent } from '../interfaces/pusher.interfaces';
import { AppLog } from '../util/log.util';
import { environment } from '../../../environments/environment';
import { CoreService } from './core.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  alive: boolean = false;

  pusher: any;

  userChannel: any = false;

  nyomdaChannel: any = false;

  private pusherEvents: BehaviorSubject<IPusherEvent[]> = new BehaviorSubject([]);

  public events = this.pusherEvents.asObservable();

  // TODO pre-koncepció: apinként tárolnánk az event üzeneteket?? esetleg mondjuk lehetne event típusonként is
  // public messageApiSubject: BehaviorSubject<IPusherMessage[]> = new BehaviorSubject([]);
  
  constructor(private coreService: CoreService) { 

    try {
      
      this.pusher = new Pusher(
        environment.pusher.key,
        {
          cluster: environment.pusher.cluster
        }
      );

      this.alive = true;

    } catch (e) {
      AppLog.log('Pusher connection failed because unknown error occured', [e.message]);
    }

  }

  initService(activeUserId: number): void {

    try {

      this.userChannel = this.pusher.subscribe(`user-${activeUserId}`); 
      this.nyomdaChannel = this.pusher.subscribe(`nyomda-${this.coreService.getNyomdaId()}`); 

      this.pusher.logToConsole = environment.debugOptions.pusher;
      this.pusher.connection.bind("connected", this.connectedExecute());

    } catch(e) {
      AppLog.log('Pusher event bind failed because unknown error occured', [e.message]);
    }
    
  }

  /**
   * Minden központi bindelésünket itt kezeljük, mivel az app szinten "perzisztált" 
   * behavior subjecteket is itt kezeljük.
   */
  connectedExecute(): void {
    
    if (environment.debug) {

      console.log('[DEBUG][Pusher] Instance connected');

      this.pusher.allChannels().forEach(channel => {
        console.log('[DEBUG][Pusher] Channel subscription: ' + channel.name);
      });
    }

  }

  subscribeUserEvent(event: string, callback: (data: unknown, parent: unknown) => void, parent: unknown): void {        
    this.userChannel.bind(event, data => callback(data, parent));
  }

  subscribeNyomdaEvent(event: string, callback: (data: unknown, parent: unknown) => void, parent: unknown): void {        
    this.nyomdaChannel.bind(event, data => callback(data, parent));
  }  

  /**
   * Minden központi bindelésünket itt kezeljük, mivel az app szinten "perzisztált" 
   * behavior subjecteket is itt kezeljük.
   */
  bindMessageAPI(): void {

      // message api általános bind, amennyiben találunk ilyen channelt
      if (this.pusher.allChannels().find(c => c.name === PusherChannels.MESSAGE_API)) {

        const channel = this.pusher.allChannels().find(c => c.name === PusherChannels.MESSAGE_API); 
        channel.bind('general', (data: IPusherMessage) => {      
          
          let eventList = this.pusherEvents.value as unknown[] as IPusherEvent[];  
          eventList.push(
            (new PusherEvent)
              .setChannel(PusherChannels.MESSAGE_API)
              .setDateTime(data.datetime)
              .setMessage(data)
          );

          this.pusherEvents.next(eventList);                 
        });
  
      }

  }

  /**
   * Minden központi bindelésünket itt kezeljük, mivel az app szinten "perzisztált" 
   * behavior subjecteket is itt kezeljük.
   */
  bindCallasAPI(): void {

      // message api általános bind, amennyiben találunk ilyen channelt
      if (this.pusher.allChannels().find(c => c.name === PusherChannels.CALLAS_API)) {

        const channel = this.pusher.allChannels().find(c => c.name === PusherChannels.CALLAS_API); 
        channel.bind('general', (data: IPusherMessage) => {      
          
          let eventList = this.pusherEvents.value as unknown[] as IPusherEvent[];  
          eventList.push(
            (new PusherEvent)
              .setChannel(PusherChannels.CALLAS_API)
              .setDateTime(data.datetime)
              .setMessage(data)
          );

          this.pusherEvents.next(eventList);                 
        });
  
      }
      
  }

  /**
   * Kényelmi funkció, ami visszaadja nekünk az argumentumban kapott channel esemény listáját
   * 
   * @param channel 
   * @returns 
   */
  getChannelEvents(channel: string): IPusherEvent[] {
    return this.pusherEvents.value.filter(c => c.channel === channel);
  }

  /**
   * Kényelmi funkció, ami visszaadja nekünk a teljes behavior subject tartalmat
   * 
   * @returns 
   */
  getCurrentEvents(): IPusherEvent[] {
    return this.pusherEvents.value;
  }

  getEvents(): BehaviorSubject<IPusherEvent[]> {
    return this.pusherEvents;
  }

  /**
   * Egy adott channel nevet ellenőriz a feliratkozások között
   * 
   * @param channel 
   * @returns 
   */
  private isChannelSubscribed(channel: string): boolean {
    
    let a = false;
    this.pusher.allChannels().forEach(c => {
      if (c.name === channel) a = true;
    });

    return a;
  }

}
