import { Entities } from "@scrollmax/core-nextgen";
import { AllapotEnumerations, AllapotKodToType, TaskaAllapotToType } from "../../../models/printportal";

export class SpTaska extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpTaska';

    /** @var number */
    override id: number;
    
    /** @var number */
    szam: number;

    /** @var number */
    taskaszam: number;

    /** @var number */
    partner: number;

    /** @var string */
    partnernev: string;

    /** @var string */
    cim: string;

    /** @var string */
    taska_allapot: string;

    /** @var boolean */
    elotaska: boolean;

    /** @var string */
    anyagle: string;

    /** @var string */
    szallitas: string;

    /** @var string */
    kontakt_nev: string;

    /** @var number */
    kontakt_kod: number;

    /** @var string */
    imprimalo_nev: string | null;

    /** @var number */
    imprimalo_kod: number | null;

    /** @var string */
    anyagle_nev: string | null;

    /** @var number */
    anyagle_kod: number | null;

    /** @var string */
    gepindul_nev: string | null;

    /** @var number */
    gepindul_kod: number | null;

    /** @var string */
    anyagle_hatarido: string;

    /** @var number */
    anyagle_allapot: number;

    /** @var string */
    imprimalas_hatarido: string;

    /** @var number */
    imprimalas_allapot: number;

    /** @var string */
    rendszam: string;

    /** @var number */
    elotaska_szam: number;

    /** @var number */
    fajl_db: number;

    /** @var boolean */
    kelltdnyilatkozat: boolean;

    /** @var unknown */
    oldalkepek: unknown[];

    visszajelzeskell: boolean = false;

    static columnToggleReference(): string[] {
        return [
            'taska_allapot',
            'anyagle',
            'szallitas',
            'kontakt_nev',
            'imprimalo_nev',
            'anyagle_nev',
            'gepindul_nev',
            'anyagle_hatarido',
            'anyagle_allapot',    
            'imprimalas_hatarido',
            'imprimalas_allapot'
        ];
    }

    override getId(): number {
        return this.szam;
    }

    /**
     * Táska száma
     * @returns 
     */
    getTaskaszam(): number {
        return this.taskaszam;
    }

    /**
     * Táska cím (megnevezés, stb.)
     * @returns 
     */
    getTaskaCim(): string {
        return this.cim;
    }

    /**
     * Taska allapot
     * @returns 
     */
    getTaskaAllapot(): string {
        return this.taska_allapot;
    }

    /**
     * Táskéhoz csatolt fileok száma (ha van)
     * @returns 
     */
    getTaskaFileSzam(): number {
        return this.fajl_db;
    }

    isElotaska(): boolean {
        return this.elotaska;
    }

    getElotaskaSzam(): number {
        return this.elotaska_szam;
    }

    getRendelesSzam(): string {
        return this.rendszam;
    }

    /**
     * Helper a partner kódhoz, amit itt a belső propertykben máshogyan hívunk
     * @returns 
     */
    getPartnerKod(): number {
        return this.partner;
    }

    /**
     * Helper a partner névhez, amit itt a belső propertykben stringként tartunk 
     * nyilván és nem relációként!
     * 
     * @returns 
     */
    getPartnerNev(): string {
        return this.partnernev;
    }

    /**
     * PP űrlap dátum (anyagleadás)
     * @returns 
     */
    getAnyagleadasDatum(): string {
        return this.anyagle;
    }

    /**
     * PP űrlap dátum (szállítás)
     * @returns 
     */   
    getSzallitasDatum(): string {
        return this.szallitas;
    }

    getKontaktKod(): number {
        return this.kontakt_kod;
    }

    getKontaktNev(): string {
        return this.kontakt_nev;
    }

    getImprimaloKod(): number {
        return this.imprimalo_kod;
    }

    getImprimaloNev(): string {
        return this.imprimalo_nev;
    }

    getGepindulasKod(): number {
        return this.gepindul_kod;
    }

    getGepindulasNev(): string {
        return this.gepindul_nev;
    }

    getAnyagleadasHatarido(): string {
        return this.anyagle_hatarido;
    }

    getAnyagleadasAllapot(): number {
        return this.anyagle_allapot;
    }

    getImprimalasHatarido(): string {
        return this.imprimalas_hatarido;
    }

    getImprimalasAllapot(): number {
        return this.imprimalas_allapot;
    }

    getTDNyilatkozat(): boolean {
        return this.kelltdnyilatkozat;
    }

    getOldalkepek(): unknown[] {
        return this.oldalkepek;
    }

    isPreflightJobActive(): boolean {
        return this.visszajelzeskell;
    }

    //
    // Helyben csinálunk helpereket az állapotok badge és text színeire, mert nagyon sok helyen kell használni őket
    //

    /**
     * Fő táska állapot fordítási tag
     * @returns 
     */
    getTaskaAllapotLabel(): string {
        return AllapotEnumerations.GetEnumerationArray().find(l => l.__toString() === TaskaAllapotToType.GetAllapotType(this.taska_allapot)?.toString())?.getLabel ?? 'enum_label_no_data';
    }

    /**
     * Anyagleadás badge color
     * @returns
     */
    getAnyagleadasBadgeColor(): string {
        return AllapotKodToType.GetBadgeColor(AllapotKodToType.GetType(this.anyagle_allapot));
    }

    /**
     * Anyagleadás text color
     * @returns 
     */
    getAnyagleadasTextColor(): string {
        return AllapotKodToType.GetTextColor(AllapotKodToType.GetType(this.anyagle_allapot));
    }

    /**
     * Anyagleadás fordítási tag
     * @returns 
     */
    getAnyagleadasAllapotKodLabel(): string {
        return AllapotEnumerations.GetEnumerationArray().find(l => l.__toString() === this.anyagle_allapot?.toString())?.getLabel ?? 'enum_label_no_data';
    }

    /**
     * Imprimálás (jóváhagyás) badge color
     * @returns 
     */
    getJovahagyasBadgeColor(): string {
        return AllapotKodToType.GetBadgeColor(AllapotKodToType.GetType(this.imprimalas_allapot)); 
    }

    /**
     * Imprimálás (jóváhagyás) text color
     * @returns 
     */
    getJovahagyasTextColor(): string {
        return AllapotKodToType.GetTextColor(AllapotKodToType.GetType(this.imprimalas_allapot));
    }

    /**
     * Imprimálás (jóváhagyás) fordítási tag
     * @returns 
     */
    getJovahagyasAllapotKodLabel(): string {
        return AllapotEnumerations.GetEnumerationArray().find(l => l.__toString() === this.imprimalas_allapot?.toString())?.getLabel ?? 'enum_label_no_data';
    }

    getJovahagyasAllapotVisual() {

        if ((this.imprimalas_hatarido !== null) && (this.imprimalas_allapot !== 5 && this.imprimalas_allapot !== 3 && this.imprimalas_allapot !== 10 && this.imprimalas_allapot > -1)) {
            return 1;
        } else if (this.imprimalas_allapot === 10) {
            return 2;
        } else if (this.imprimalas_allapot === 5) {
            return 3;
        } else if (this.imprimalas_allapot === 3) {
            return 4;
        }

        return 0;

    }

    override __toString(): string {
        throw new Error("Method not implemented.");
    }
    
    override validate(entity?: SpTaska): boolean {
        throw new Error("Method not implemented.");
    }

}