import { Entities } from '@scrollmax/core-nextgen';
import { OAuth2User } from './oauth-user.entity';

export class UserProfile extends Entities.CoreEntity  {

    public static override kind: string = 'stdClass';

    frontend_parameters: string;

    oAuth2Users: OAuth2User = new OAuth2User();

    getFrontendParameters(): any {
        return this.frontend_parameters;
    }

    /**
     * Relációban lévő entitások shortcut lekérdezései
     */
    override getId(): number {
        return this.oAuth2Users.getId();
    }

    /*
    setName(last_name: string, first_name: string): void {
        this.oAuth2Users.setName(last_name, first_name);
    }

    getName(): string {
        return this.oAuth2Users.getName();
    }

    getFirstName(): string {
        return this.oAuth2Users.getFirstName();
    }

    getLastName(): string {
        return this.oAuth2Users.getLastName();
    }

    setEmail(email: string): void {
        this.oAuth2Users.setEmail(email);
    }

    getEmail(): string {
        return this.oAuth2Users.getEmail();
    }

    getScopes(): string[] {
        return this.oAuth2Users.getScopes();
    }

    getClientId(): number {
        return this.oAuth2Users.getClientId();
    }

    getClientName(): string {
        return this.oAuth2Users.getClientName();
    }

    getClientScopes(): string[] {
        return this.oAuth2Users.getClientScopes();
    }        

    setPassword(password: string): void {
        this.oAuth2Users.password = password;
    }
    */

    /**
     * Technikailag ezt nem érjük el sohasem, mivel nam adjuk vissza. A getter léte pusztán annyi, hogy regisztráció 
     * esetén vissza tudjuk olvasni frontend oldalon ezt a tulajdonságot.
     */
    // getPassword(): string {
    //    return this.oAuth2Users.password;
    // }
    
}
