import { Entities } from "@scrollmax/core-nextgen";

// TODO ez az egész lenne az SP diszpó? vagy az mi a fasz?
export class Diszpo extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\Diszpo';

    /** 
     * Nem fontos nekünk, nem használjuk - placeholder 
     * @var number 
     */
    override id: number;

    /**
     * @var number
     */
    kod: number;

    /**
     * @var string
     */
    datum: string;

    /**
     * FONTOS ide nem dátum stringet várunk, hanem megjegyzéseket: reggel, este, napközben, stb.
     * @var string
     */
    ido: string;

    /**
     * @var string
     */
    ceg: string;

    /**
     * @var string
     */
    isz: string;

    /**
     * @var string
     */
    utca: string;

    /**
     * @var string
     */
    varos: string;

    /**
     * @var string
     */
    kontakt: string;

    /**
     * @var string
     */
    tel: string;

    /**
     * @var number
     */
    pld: number;

    /**
     * @var number
     */
    leszallitva: number;

    /**
     * @var boolean
     */
    szallithato: boolean;

    /**
     * @var string
     */
    me: string;

    /**
     * Diszpó dátum
     * @returns 
     */
    getDate(): string {
        return this.datum;
    }

    /**
     * Időszakot adunk vissza (reggel, este, stb.)
     * @returns 
     */
    getIdoszak(): string {
        return this.ido;
    }

    /**
     * Formázott cím string: {irsz} {város}, {utca (hrsz)}
     * @returns
     */
    getAddressString(): string {
        return [[this.isz, this.varos].join(' '), this.utca].join(', ');
    }

    /**
     * Cégnév, ha van
     * @returns 
     */
    getCompany(): string {
        return this.ceg;
    }

    /**
     * Telefonszám, ha van
     * @returns 
     */
    getPhone(): string {
        return this.tel;
    }

    /**
     * A kontakt nevet ha van
     * @returns
     */
    getContactName(): string {
        return this.kontakt;
    }

    /**
     * A mennyiség mértékegysége
     * @returns 
     */
    getMeasure(): string {
        return this.me;
    }

    /**
     * Szállítható mennyiség
     * @returns 
     */
    getQty(): number {
        return this.pld;
    }

    /**
     * Szállítható mennyiség formázva: {qty} {meaasure}
     * @returns 
     */
    getQtyString(): string {
        return [this.getQty(), this.getMeasure()].join(' ');
    }

    /**
     * Leszállított mennyiség
     * @returns 
     */
    getQtyDelivered(): number {
        return this.leszallitva;
    }

    /**
     * Leszállított mennyiség formázva: {qty} {meaasure}
     * @returns
     */
    getQtyDeliveredString(): string {
        return [this.getQtyDelivered(), this.getMeasure()].join(' ');
    }

    isDiszpoPartiallyDelivered(): boolean {
        return (this.getQtyDelivered() > 0 && (this.getQty() - this.getQtyDelivered()) > 0);
    }

    /**
     * Tervezett státusz
     * @returns 
     */
    isDiszpoTervezett(): boolean {
        return !this.szallithato;
    }

    override __toString(): string {
        throw new Error("Method not implemented.");
    }
    
    override validate(entity?: Diszpo): boolean {
        throw new Error("Method not implemented.");
    }
}