import { Injectable, Signal, signal } from '@angular/core';
import { Nyomda } from '../entity/core/auth';
import { BehaviorSubject, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { CoreResponse, EntityService } from '@scrollmax/core-nextgen';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppLog } from '../util/log.util';

import { PreloadEntity } from '../entity/core/preload.entity';
import { ScrollParam, ScrollParamCollection } from '../entity/scroll/scrollparam.entity';
import { UploadProgress } from '../entity/printportal';
import { IScrollJogok } from '../interfaces/scroll.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

    // maximum feltölthető méret (MB)
    public static maxFileSize: number = 250;

    private _validFileTypes: string[] = [
      'image/jpg', 'image/jpeg', 'image/png', 'application/pdf'
    ]

    /**
     * Ez a változó fogja tartalmazni az aktuálisan kiválasztott nyomdát
     * 
     * @var ReplaySubject
     */
    currentNyomda: ReplaySubject<Nyomda> = new ReplaySubject(1);
    
    /**
     * Kényelmi metódus a nyomda adatainak eléréséhez
     */
    activeNyomda: Nyomda;

    /**
     * @var BehaviorSubject
     */
    private nyomdaSubject: BehaviorSubject<Nyomda[]> = new BehaviorSubject([]);

    /**
     * @var Observable
     */
    nyomda$ = this.nyomdaSubject.asObservable();

    // preload deklarációk
    preloadEntity: PreloadEntity = new PreloadEntity();
    preloadSubject: ReplaySubject<PreloadEntity> = new ReplaySubject(null);

    // upload flow kezelése
    uploadActive = signal<boolean>(false);

    private uploadItems: BehaviorSubject<UploadProgress[]> = new BehaviorSubject([]);
    currentUploadItems$ = this.uploadItems.asObservable();

    // scrollparams state signal alapon
    scrollParamsState = signal<ScrollParamCollection>(null);

    // aktuális partnerek state signal alapon (preloadnál már visszakapjuk, elég ha ezt visszük végig a state-ben)
    scrollPartnerPermissions = signal<IScrollJogok[]>([]);

    /**
      * Entitások előtöltésekre kerültek -e
      */
    private preloaded = false;

    constructor(
      private http: HttpClient, 
      private entityService: EntityService
    ) { 

      this.currentNyomda.next(new Nyomda());
    }

    /**
     * @param value 
     */
    setPreloaded(value: boolean): void {
      this.preloaded = value;
    }
  
    /**
     * Megmondja nekünk, hogy előtöltöttük-e a preload entitást
     * @returns 
     */
    isPreloaded(): boolean {
      return this.preloaded;
    }
  
    /**
     * Seteljük a preload entitásunkat. Ez egy csomó olyan dolgot tartalmazhat amit csak egyszer, illetve 
     * előre még a felület felépítése előtt szükséges betöltenünk. Azokat az entitás szetteket, amiket nem 
     * csak egyszer töltünk be, már itt a besetelés során próbáljuk meg kezelni. 
     * 
     * @param value 
     */
    setPreloadEntity(value: PreloadEntity): void {
  
      // preload entitás setelése
      this.preloadEntity = value;

      // scrollparams felúzása, signal state-ben tároljuk, mert mindenhol el akarjuk érni
      const paramsCollection = new ScrollParamCollection();
      this.preloadEntity.params.forEach(obj => {
        
        let param = new ScrollParam();
        param
          .setKey(obj.opcionev)
          .setValue(obj.ertek);
      
        paramsCollection.add(param);  
      });

      this.scrollParamsState.set(paramsCollection);

      // partner kódok és nevek felhúzása, signal state-ben tároljuk mert a filterek számára el akarjuk érni
      const permissionCollection: IScrollJogok[] = [];
      
      this.preloadEntity.jogok.forEach(obj => { 
        permissionCollection.push(obj as IScrollJogok); 
      });

      this.scrollPartnerPermissions.set(permissionCollection);

      // preload entiás setelése behavior subjectbe is
      this.preloadSubject.next(value);
      // beállítjuk, hogy preloadoltunk
      this.setPreloaded(true);
   
    }
  
    /**
     * Bármikor meghívható metódus a preload entitások frissítéséhez. Ilyenre simán szükségünk lehet, hiszen a 
     * felhasználó válthat nyomdát pl.
     */
    onPreload(): void {
      if (this.getNyomdaId() > 0) {
        this.http.get(`${environment.endpoints.app}/pp_preload_v2/${this.getNyomdaId()}`).pipe(map((r) => { 
          this.setPreloadEntity(r['data'].payload as PreloadEntity);
        })).subscribe();
      } else {
        AppLog.message('Preloading not available for ID 0');
      }
    }

    /**
     * Az aktív nyomda azonosítójának meghatározása a localSatorage alapján (ez a nyomdaválasztás megtartásához szükséges)
     */
    getNyomdaId(): number {
      return parseInt(localStorage.getItem(environment.storage.partner), 0);
    }

    setNyomdaId(id: number): void {
      localStorage.setItem(environment.storage.partner, id.toString());
    }

    /**
     * Nyomdák listájának betöltése.
     * 
     * @param filters 
     */
    onLoadNyomdak(): void {

      AppLog.message('Query server for available nyomda records');

      this.http.get(`${environment.endpoints.app}/nyomda`, {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem(environment.storage.token))
      }).pipe(map(r => this.entityService.parse(r).getResponse())).subscribe((r: CoreResponse) => {
        if (r instanceof CoreResponse) {

          const nyomdak: Nyomda[] = r.getData<Nyomda[]>() as unknown[] as Nyomda[]
          
          // ha csak egy nyomdánk van, akkor forceoljuk a választást
          if (nyomdak.length == 1) {

            AppLog.log('Forcing nyomda object', nyomdak[0]);
            
            this.currentNyomda.next(nyomdak[0]);
            this.activeNyomda = nyomdak[0];

            this.setNyomdaId(nyomdak[0].getId());
          }

          this.nyomdaSubject.next(nyomdak);
        }
      });
    }

    /**
     * Feltölteni kívánt állomány validációja kiterjesztés szerint.
     * 
     * @param type 
     * @param custom 
     * @returns 
     */
    public validateUploadFile(type: string, custom?: string[]): boolean {

      if (custom) {
        if (custom.includes(type))
          return true;
  
        return false;
      }
  
      if (this._validFileTypes.includes(type))
        return true;
  
      return false;

    }

    /**
     * Elkészítünk egy nevesített upload progress-t
     * @param name 
     */
    public createUploadProgress(name: string): void {

      const up = new UploadProgress(name);      

      // lekérdezzük a behavior subjectet és hozzáadjuk az új feltöltést
      const values = this.uploadItems.getValue();
      values.push(up)
      
      // aktívra álltjuk a feltöltést és frissítjük a behavior subjectet
      this.uploadActive.set(true);
      this.uploadItems.next(values);    
    }

    /**
     * Frissítjük az upload progress-t
     * 
     * @param name 
     * @param progress 
     */
    public updateUploadProgress(name: string, progress: number): void {
      // lekérjük a behaviorsubject értékeit
      const values = this.uploadItems.getValue();
      
      // felülírjuk a progress értéket
      if (values.find(p => p.getName() === name) instanceof UploadProgress) {
        values.find(p => p.getName() === name).setProgress(progress);
      }    
  
      if (progress === 100) {
        values.splice(values.findIndex(p => p.getName() === name));
      }
  
      if (values.length === 0) {
        this.uploadActive.set(false);
      }
  
      // seteljük a behaviorsubjectnek
      this.uploadItems.next(values);
    }

}
