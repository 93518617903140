import { Entities } from "@scrollmax/core-nextgen"

export class SpUzenetFej extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpUzenetFej';

    ppuz_tipus: string;

	ppuz_tipus_kod: number;

	ppuz_x_ins_date: string;

	ppuz_nev: string;

	ppuz_utolso: string;

    // egyedi mező
    ppuz_fej_cim: string;
    
    // egyedi mező
    ppuz_fej_nyomda: string;

    getCim(): string {
        return this.ppuz_fej_cim;
    }

    setCim(value: string): SpUzenetFej {
        this.ppuz_fej_cim = value;
        return this;
    }

    getTipus(): string {
        return this.ppuz_tipus;
    }

    setTipus(value: string): SpUzenetFej {
        this.ppuz_tipus = value;
        return this;
    }

    getTipusKod(): number {
        return this.ppuz_tipus_kod;
    }

    setTipusKod(value: number): SpUzenetFej {
        this.ppuz_tipus_kod = value;
        return this;
    }

    getNyomdaNev(): string {
        return this.ppuz_fej_nyomda;
    }

    override getCreatedAt(): string {
        return this.ppuz_x_ins_date;
    }

    /**
     * Az utolsó hozzászóló neve!
     * @returns 
     */
    getNev(): string {
        return this.ppuz_nev;
    }

    /**
     * A felhasználó volt az utolsó?
     * @returns 
     */
    isOwnLast(): boolean {
        return (this.ppuz_utolso === 'ppuser');
    }

    override __toString(): string {
        return this.ppuz_nev;
    }
}