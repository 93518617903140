import { formatDate } from '@angular/common';
import { Entities } from '@scrollmax/core-nextgen';
import { ESzamlaAllapot, TSzamlaAllapot } from '../../../models/printportal';

export class SpSzamla extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpSzamla';

    szamlaszam: string;

    kod: number;

    kelt: string;

    fizetes: string;

    teljesites: string;

    storno: boolean;

    stronozott: boolean;

    partner: number;

    nev: string;

    fizmod: number;

    dnetto: number;

    dafa: number;

    dev: string;

    dfizetett: number;

    eszamla: boolean;

    vanszamla: boolean;
    
    getSzamlaszam(): string {
        return this.szamlaszam;
    }

    getKod(): number {
        return this.kod;
    }

    getKelt(): string {
        return this.kelt;
    }

    getFizetesiDatum(): string {
        return this.fizetes;
    }

    getTeljesitesiDatum(): string {
        return this.teljesites;
    }

    isStorno(): boolean {
        return this.storno;
    }

    isStornozott(): boolean {
        return this.stronozott;
    }

    getPartnerKod(): number {
        return this.partner;
    }

    getPartnerNev(): string {
        return this.nev;
    }

    getFizetesiMod(): number {
        return this.fizmod;
    }

    getFizetesiModLabel(): string {
        switch(this.fizmod) {
            case 1:
                return 'payment_mode_label_cash';
                break;
            case 2:
                return 'payment_mode_label_bank_transfer'
                break;
            case 3:
                return 'payment_mode_label_mail_order';
                break;
            case 4:
                return 'payment_mode_label_credit_card';
                break;
            case 5:
                return 'payment_mode_label_check';
                break;
            case 6:
                return 'payment_mode_label_bartel';
                break;
            case 7:
                return 'payment_mode_label_compensation';
                break;
            case 8:
                return 'payment_mode_label_promissory_note';
                break;
            case 9:
                return 'payment_mode_label_group_payment_notice';
                break;
            default:
                return 'label_no_data';
                break;
        }
    }

    getNetto(): number {
        return this.dnetto;
    }

    getBrutto(): number {
        return this.dnetto + this.dafa;
    }

    getAfa(): number {
        return this.dafa;
    }

    getPenznem(): string {
        return this.dev;
    }

    /**
     * Eddig kifizetett (?)
     */
    getFizetett(): number {
        return this.dfizetett;
    }

    /**
     * E-számla
     * @returns 
     */
    isEszamla(): boolean {
        return this.eszamla;
    }

    /**
     * E-számla minden esetben hiteles másolat
     * @returns
     */
    isHiteles(): boolean {
        return (this.eszamla);
    }

    /**
     * Ha van számla, akkor engedjük a letöltést
     * @returns 
     */
    isDownloadable(): boolean {
        return this.vanszamla;
    }

    isKiegyenlitett(): boolean {

        const brutto: number = this.dnetto + this.dafa;
        const fizetett: number = this.dfizetett;
        
        return (brutto <= fizetett);
    }

    /**
     * Dátum szerint lejárt -e (többi jelenleg nem érdekes)
     * @returns 
     */
    isLejart(): boolean {
        let dt = new Date();
        return dt > new Date(this.fizetes);
    }

    getShortStatus(): string {

        // ha kiegyenlített akkor nincs probléma
        if (this.isStorno())
            return 'storno'

        // ha kiegyenlített akkor nincs probléma
        if (this.isKiegyenlitett())
            return 'ok'

        // dátum szerint még nem járt le, de még nincs kiegyenlítve
        if (!this.isLejart() && !this.isKiegyenlitett())
            return 'need_payment';

        if (this.isLejart() && !this.isKiegyenlitett())
            return 'outdated'

        return 'default';
    }

    getStatus(): string {

        switch(this.getShortStatus()) {
            case 'storno':
                return 'szamla_statusz_label_sztorno';
            case 'ok':
                return 'szamla_statusz_label_kifizetve';
            case 'need_payment':
                return 'szamla_statusz_label_kifizetendo';
            case 'outdated':
                return 'szamla_statusz_label_lejart_tartozas';
            default:
                return 'szamla_statusz_label_hiba'
        }
        
    }

    getStatusRawValue(): TSzamlaAllapot {

        // ha kiegyenlített akkor nincs probléma
        if (this.isStorno())
            return ESzamlaAllapot.SZTORNO;

        // ha kiegyenlített akkor nincs probléma
        if (this.isKiegyenlitett())
            return ESzamlaAllapot.KIEGYENLITETT;

        // dátum szerint még nem járt le, de még nincs kiegyenlítve
        if (!this.isLejart() && !this.isKiegyenlitett())
            return ESzamlaAllapot.NYITOTT;

        if (this.isLejart() && !this.isKiegyenlitett())
            return ESzamlaAllapot.LEJART;

        return ESzamlaAllapot.NYITOTT;
    }

}
