import { Entities } from "@scrollmax/core-nextgen";

export class SpLevelKerdes extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpLevelKerdes';

    override id: number;

    prior: number;

    kotelezo: boolean;

    nev: string;

    leiras: string;

    ertek: string;

    ertekArray: string[] = [];

    ertekSelected: string;

    selected: boolean;

    getPrioritas(): number {
        return this.prior;
    }

    isRequired(): boolean {
        return this.kotelezo;
    }

    getNev(): string {
        return this.nev;
    }

    getLeiras(): string {
        return this.leiras;
    }

    getErtek(): string {
        return this.ertek;
    }

    /**
     * Setter az értékhez, hiszen ezt fogjuk feltolni majd a backend felé
     * @param value 
     * @returns 
     */
    setErtek(value: string): SpLevelKerdes {
        this.ertek = value;
        return this;
    }

    getErtekArray(): string[] {
        return this.ertekArray;
    }

    /**
     * Kényelmi funkció a dropdown alapú kérdésekhez
     * @returns 
     */
    isSelectable(): boolean {
        return (this.ertekArray.length > 0);
    }

    isSelected(): boolean {
        return this.selected;
    }

    setSelected(value: boolean): SpLevelKerdes {
        this.selected = value;
        return this;
    }    

}