/**
 * Printportal direktívákban használjuk a duplikáció elkerülésére, amikor automatikusan egy-egy 
 * változtatásnál töröljük ezeket az osztályokat (ha vannak). 
 */
export const badgeColors: string[] = [
    'badge-default',
    'badge-primary',
    'badge-success',
    'badge-info',
    'badge-danger',
    'badge-warning',    
    'badge-dark'
];

export const textColors: string[] = [
    'text-blue-500',
    'text-amber-500',
    'text-lime-500',
    'text-red-500',
    'text-sky-500'
]

/**
 * Letölthető állományaink lehetséges értékeinek felsorolása
 */
export enum EDownloadableFile {
    FILESERVICE,
    SZAMLA
}

/**
 * Letölthető állományaink típusának deklarációja, enum alapján
 */
export type TDownloadableFile = EDownloadableFile.FILESERVICE | EDownloadableFile.SZAMLA;

/**
 * Scroll állapotkódok felsorolása, általában imprimálás és anyagleadás állapotoknál használjuk ezeket a numerikus értékeket
 */
export enum EAllapotKod {
    AKTIV = 0,
    FOLYAMATBAN = 1,
    PROBLEMA = 2,
    RENDBEN = 3,
    TOROLT = 4,
    NEM_SZUKSEGES = 5,
    ISMERETLEN = 999
}

/**
 * Scroll állapotkódok típusának deklarációja, enum alapján. A number-t mindenképpen használnunk kell, mivel nem tudjuk, 
 * hogy minden esetben a felsorolt értékek jönnek -e a response-ben
 */
export type TAllapotKod =  
    number |
    EAllapotKod.AKTIV |
    EAllapotKod.FOLYAMATBAN | 
    EAllapotKod.PROBLEMA | 
    EAllapotKod.RENDBEN | 
    EAllapotKod.NEM_SZUKSEGES | 
    EAllapotKod.TOROLT | 
    EAllapotKod.ISMERETLEN;

/**
 * Statikus helper osztály az állapot kódok kezeléséhez
 */
export class AllapotKodToType {

    public static GetBadgeColor(allapot: TAllapotKod): string {
        switch(allapot) {
            case EAllapotKod.AKTIV:
                return 'badge-info';
                break;
            case EAllapotKod.RENDBEN:
                return 'badge-success';
                break;
            case EAllapotKod.TOROLT:
                return 'badge-warning';
                break;
            case EAllapotKod.PROBLEMA:
                return 'badge-danger';
                break;
            default: 
                return 'badge-primary';
                break;
        }
    }

    public static GetTextColor(allapot: TAllapotKod): string {
        switch(allapot) {
            case EAllapotKod.AKTIV:
                return 'text-blue-500';
                break;
            case EAllapotKod.RENDBEN:
                return 'text-amber-500';
                break;
            case EAllapotKod.TOROLT:
                return 'text-lime-500';
                break;
            case EAllapotKod.PROBLEMA:
                return 'text-red-500';
                break;
            default: 
                return 'text-sky-500';
                break;
        }
    }

    public static GetType(allapot: number): TAllapotKod {
        switch(allapot) {
            case 0:
                return EAllapotKod.AKTIV;
            case 1:
                return EAllapotKod.FOLYAMATBAN;
            case 2:
                return EAllapotKod.PROBLEMA;
            case 3:
                return EAllapotKod.RENDBEN;
            case 4:
                return EAllapotKod.TOROLT;
            case 5:
                return EAllapotKod.NEM_SZUKSEGES;
            default:
                return EAllapotKod.ISMERETLEN;
        }
    }

}







export enum ETaskaAllapot {
    AKTIV = 'aktiv',
    SZALLITHATO = 'szallithato',
    KESZ = 'kesz',
    ELOKESZITES = 'elokeszites',
    NYOMAS = 'nyomas',
    KOTESZET = 'koteszet',
    MEGRENDELVE = 'megrendelve',
    LESZALLITVA = 'leszallitva',
    FOLYAMATBAN = 'folyamatban',
    PROBLEMA = 'problema',
    NEM_SZUKSEGES = 'nem_szukseges',
    TOROLT = 'torolt',
    RENDBEN = 'rendben',
    ALVALLALKOZO = 'alvallalkozo',
    
    // keskeny speciális
    FELDOLOGOZAS = 'feldolgozas',
    PROGRAMOZHATO = 'programozhato',
    TERMELESBEN = 'termelesben',
    SZALLITANI = 'szallitani',
    FELTOLTOTT = 'feltoltott',
    SZAMLAZNI = 'szamlazni'
}

export interface IAllapot {

    key: TTaskaAllapot | TSzamlaAllapot | TAllapotKod;

    prefix: string;

    label?: string;

    url?: string;

    template?: string;

    setKey(key: string): Allapot;

    setPrefix(prefix: string): Allapot;

    setLabel(key: string): Allapot;

    setUrl(url: string): Allapot;

    setTemplate(template: string): Allapot;

    getUrl(): string;

    getTemplate(): string;
}

export class Allapot implements IAllapot {

    key: TTaskaAllapot | TSzamlaAllapot | TAllapotKod;

    prefix = 'allapot';

    label?: string;

    url?: string;

    template?: string;

    public get getLabel(): string | null {
        return this.label;
    }

    setKey(key: string): Allapot {
        this.key = key;
        return this;
    }

    setPrefix(prefix: string): Allapot {
        this.prefix = prefix;
        return this;
    }

    setLabel(key: string): Allapot {
        this.label = 'enum_label_' + this.prefix + '_' + key;
        return this;
    }

    setUrl(url: string): Allapot {
        this.url = url;
        return this;
    }

    setTemplate(template: string): Allapot {
        this.template = template;
        return this;
    }

    getUrl(): string {
        return this.url;
    }

    getTemplate(): string {
        return this.template;
    }

    __toString(): string {
        return this.key.toString();
    }

}

export type TTaskaAllapot = string |
    ETaskaAllapot.AKTIV |
    ETaskaAllapot.SZALLITHATO |
    ETaskaAllapot.KESZ |
    ETaskaAllapot.ELOKESZITES |
    ETaskaAllapot.NYOMAS |
    ETaskaAllapot.KOTESZET |
    ETaskaAllapot.MEGRENDELVE |
    ETaskaAllapot.LESZALLITVA |
    ETaskaAllapot.FOLYAMATBAN |
    ETaskaAllapot.PROBLEMA | 
    ETaskaAllapot.TOROLT | 
    ETaskaAllapot.RENDBEN | 
    ETaskaAllapot.NEM_SZUKSEGES | 
    ETaskaAllapot.ALVALLALKOZO |

    // keskeny speciális
    ETaskaAllapot.FELDOLOGOZAS | 
    ETaskaAllapot.PROGRAMOZHATO | 
    ETaskaAllapot.TERMELESBEN | 
    ETaskaAllapot.SZALLITANI | 
    ETaskaAllapot.FELTOLTOTT | 
    ETaskaAllapot.SZAMLAZNI;

export class TaskaAllapotToType {

    public static GetAllapotBadgeColor(allapot: TTaskaAllapot): string {

        switch(allapot) {
            case ETaskaAllapot.AKTIV:
            case ETaskaAllapot.ELOKESZITES:
            case ETaskaAllapot.FOLYAMATBAN:
            case ETaskaAllapot.KOTESZET:
            case ETaskaAllapot.NYOMAS:
            case ETaskaAllapot.ALVALLALKOZO:
                return 'badge-info';
                break;
            case ETaskaAllapot.KESZ:
            case ETaskaAllapot.SZALLITHATO:
                return 'badge-success';
                break;
            case ETaskaAllapot.LESZALLITVA:
                return 'text-lime-500';
                break;
            case ETaskaAllapot.PROBLEMA:
                return 'badge-danger';
                break;
            default: 
                return 'badge-outline';
                break;
        }

    }

    public static GetAllapotTextColor(allapot: TTaskaAllapot): string {

        switch(allapot) {
            case ETaskaAllapot.AKTIV:
            case ETaskaAllapot.ELOKESZITES:
            case ETaskaAllapot.FOLYAMATBAN:
            case ETaskaAllapot.KOTESZET:
            case ETaskaAllapot.NYOMAS:
            case ETaskaAllapot.ALVALLALKOZO:
                return 'text-blue-500';
                break;
            case ETaskaAllapot.KESZ:
            case ETaskaAllapot.SZALLITHATO:
                return 'text-amber-500';
                break;
            case ETaskaAllapot.LESZALLITVA:
                return 'text-lime-500';
                break;
            case ETaskaAllapot.PROBLEMA:
                return 'text-red-500';
                break;
            default: 
                return 'text-sky-500';
                break;
        }

    }

    public static GetAllapotType(allapot: string): TTaskaAllapot {
        switch (allapot) {
            case 'Alvállalkozó':
                return ETaskaAllapot.FOLYAMATBAN;
                break;
            case 'Aktív':
                return ETaskaAllapot.AKTIV;
            break;
            case 'Szállítható':
                return ETaskaAllapot.SZALLITHATO;
                break;
            case 'Kész':
                return ETaskaAllapot.KESZ;
                break;
            case 'Előkészítés':
                return ETaskaAllapot.ELOKESZITES;
                break;
            case 'Nyomás':
                return ETaskaAllapot.NYOMAS;
                break;
            case 'Kötészet':
                return ETaskaAllapot.KOTESZET;
                break;
            case 'Megrendelve':
                return ETaskaAllapot.MEGRENDELVE;
                break;
            case 'Leszállítva':
                return ETaskaAllapot.LESZALLITVA;
                break;
            case 'Folyamatban':
                return ETaskaAllapot.FOLYAMATBAN;
                break;
            case 'Probléma':
                return ETaskaAllapot.PROBLEMA;
                break;
            case 'Feldolgozás': 
                return ETaskaAllapot.FELDOLOGOZAS;
                break;
            case 'Programozható':
                return ETaskaAllapot.PROGRAMOZHATO;
                break;
            case 'Termelésben':
                return ETaskaAllapot.TERMELESBEN;
                break;
            case 'Szállítani':
                return ETaskaAllapot.SZALLITANI;
                break;
            case 'Feltöltött':
                return ETaskaAllapot.FELTOLTOTT;
                break;
            case 'Számlázni':
                return ETaskaAllapot.SZAMLAZNI;
                break;
            default:
                return ETaskaAllapot.AKTIV;
                break;
        }
    }

}

export type TSzamlaAllapot = string |
    ESzamlaAllapot.NYITOTT |
    ESzamlaAllapot.KIEGYENLITETT |
    ESzamlaAllapot.LEJART |
    ESzamlaAllapot.SZTORNO;

export enum ESzamlaAllapot {
    NYITOTT = 'nyitott',
    KIEGYENLITETT = 'kiegyenlitett',
    LEJART = 'lejart',
    SZTORNO = 'sztorno',
}


export class SzamlaAllapotToType {

    public static GetAllapotBadgeColor(allapot: TSzamlaAllapot): string {

        switch(allapot) {
            case ESzamlaAllapot.KIEGYENLITETT:
                return 'badge-success';
            case ESzamlaAllapot.NYITOTT:
                return 'badge-info';
            case ESzamlaAllapot.SZTORNO:
            case ESzamlaAllapot.LEJART:
                return 'badge-danger';
            default:
                return 'badge-info';
        }

    }

    public static GetAllapotTextColor(allapot: TSzamlaAllapot): string {

        switch(allapot) {
            case ESzamlaAllapot.KIEGYENLITETT:
                return 'text-lime-500';
            case ESzamlaAllapot.NYITOTT:
                return 'text-blue-500';
            case ESzamlaAllapot.SZTORNO:
            case ESzamlaAllapot.LEJART:
                return 'text-red-500';
            default: 
                return 'text-sky-500';
                break;
        }

    }

    public static GetAllapotType(allapot: string): TSzamlaAllapot {
        switch (allapot) {
            case 'nyitott':
                return ESzamlaAllapot.NYITOTT;
                break;
            case 'kiegyenlitett':
                return ESzamlaAllapot.KIEGYENLITETT;
            break;
            case 'lejart':
                return ESzamlaAllapot.LEJART;
                break;
            case 'sztorno':
                return ESzamlaAllapot.SZTORNO;
                break;
            default:
                return ESzamlaAllapot.NYITOTT;
                break;
        }
    }

}

/**
 * Statikus helper metódus a fordítási kulcsok menedzseléséhez és visszaadásához
 */
export class AllapotEnumerations {

    static PREFIX_TASKA_ALLAPOT: string = 'taska_allapot';
    static PREFIX_SZAMLA_ALLAPOT: string = 'szamla_allapot';
    static PREFIX_INTERAKCIO_ALLAPOT: string = 'taska_interakcio_allapot';

    public static GetEnumerationArray(): Allapot[] {

        const allapotList = [];
        // alapértelmezett PP táska állapotok
        allapotList.push(new Allapot().setKey(ETaskaAllapot.AKTIV).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.AKTIV));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.ELOKESZITES).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.ELOKESZITES));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.KESZ).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.KESZ));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.KOTESZET).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.KOTESZET));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.LESZALLITVA).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.LESZALLITVA));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.MEGRENDELVE).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.MEGRENDELVE));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.NYOMAS).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.NYOMAS));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.SZALLITHATO).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.SZALLITHATO));

        // alapértelmezett PP számla állapotok
        allapotList.push(new Allapot().setKey(ESzamlaAllapot.NYITOTT).setPrefix(AllapotEnumerations.PREFIX_SZAMLA_ALLAPOT).setLabel(ESzamlaAllapot.NYITOTT));
        allapotList.push(new Allapot().setKey(ESzamlaAllapot.KIEGYENLITETT).setPrefix(AllapotEnumerations.PREFIX_SZAMLA_ALLAPOT).setLabel(ESzamlaAllapot.KIEGYENLITETT));
        allapotList.push(new Allapot().setKey(ESzamlaAllapot.LEJART).setPrefix(AllapotEnumerations.PREFIX_SZAMLA_ALLAPOT).setLabel(ESzamlaAllapot.LEJART));
        allapotList.push(new Allapot().setKey(ESzamlaAllapot.SZTORNO).setPrefix(AllapotEnumerations.PREFIX_SZAMLA_ALLAPOT).setLabel(ESzamlaAllapot.SZTORNO));

        // taska lista allapotok lehetnek (még)
        allapotList.push(new Allapot().setKey(ETaskaAllapot.FOLYAMATBAN).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.FOLYAMATBAN));
        allapotList.push(new Allapot().setKey(ETaskaAllapot.PROBLEMA).setPrefix(AllapotEnumerations.PREFIX_TASKA_ALLAPOT).setLabel(ETaskaAllapot.PROBLEMA));

        allapotList.push(new Allapot().setKey(EAllapotKod.AKTIV.toString()).setPrefix(AllapotEnumerations.PREFIX_INTERAKCIO_ALLAPOT).setLabel(ETaskaAllapot.AKTIV));
        allapotList.push(new Allapot().setKey(EAllapotKod.FOLYAMATBAN.toString()).setPrefix(AllapotEnumerations.PREFIX_INTERAKCIO_ALLAPOT).setLabel(ETaskaAllapot.FOLYAMATBAN));
        allapotList.push(new Allapot().setKey(EAllapotKod.PROBLEMA.toString()).setPrefix(AllapotEnumerations.PREFIX_INTERAKCIO_ALLAPOT).setLabel(ETaskaAllapot.PROBLEMA));
        allapotList.push(new Allapot().setKey(EAllapotKod.TOROLT.toString()).setPrefix(AllapotEnumerations.PREFIX_INTERAKCIO_ALLAPOT).setLabel(ETaskaAllapot.TOROLT));
        allapotList.push(new Allapot().setKey(EAllapotKod.RENDBEN.toString()).setPrefix(AllapotEnumerations.PREFIX_INTERAKCIO_ALLAPOT).setLabel(ETaskaAllapot.RENDBEN));
        allapotList.push(new Allapot().setKey(EAllapotKod.NEM_SZUKSEGES.toString()).setPrefix(AllapotEnumerations.PREFIX_INTERAKCIO_ALLAPOT).setLabel(ETaskaAllapot.NEM_SZUKSEGES));

        return allapotList;
    }

}

