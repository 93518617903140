import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Pipe, Directive, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';
const CORE_TRANSLATE_CONFIG_TOKEN = new InjectionToken('CoreTranslate Config Token');
class TranslateInstance {}
class TranslateOptions {}
class TranslateLocaleTransUnit {
  constructor(...args) {
    /**
     * Instances, ez a property tartalmazza a tényleges fordításokat
     * @var any[]
     */
    this.instances = [];
    this.options = new TranslateOptions();
    if (args.length > 0) {
      // ha van id paraméterünk, akkor a trans-unit tartalma feltehetőleg valid lesz
      if (Object(args[0]).hasOwnProperty('id')) {
        Object.getOwnPropertyNames(args[0]).forEach(key => {
          Object.defineProperty(this, key, {
            value: Object(args[0])[key],
            writable: true
          });
        });
      }
    }
  }
}
class TranslateLocale {
  constructor(...args) {
    this.trans_unit = [];
    if (args.length > 0) {
      if (Object(args[0]).hasOwnProperty('info')) {
        this.info = Object(args[0]).info;
      }
      if (Object(args[0]).hasOwnProperty('trans_unit')) {
        // tslint:disable-next-line:no-string-literal
        Object(args[0])['trans_unit'].forEach(unit => {
          this.trans_unit.push(new TranslateLocaleTransUnit(unit));
        });
      }
    }
  }
  getLanguages() {
    return this.info.languages;
  }
  setLocaleUnits(units) {
    this.trans_unit = units;
  }
  getLocaleUnits() {
    return this.trans_unit;
  }
  /**
   * Kényelmi metódus a tag név validációjához.
   *
   * @param tag
   * @returns
   */
  validateTranslate(tag) {
    return this.trans_unit.find(u => u.tag === tag) instanceof TranslateLocaleTransUnit;
  }
  /**
   * Beseteljük a fordítást a lokális verzióba, hogy ne kelljen újra tölteni az egész cumót.
   *
   * @param tag
   * @param ins
   */
  setTranslate(tag, inst) {
    let transUnit = this.trans_unit.find(u => u.tag === tag);
    if (transUnit) {
      inst.forEach(instance => {
        const inst = new TranslateInstance();
        inst.locale = instance.locale;
        inst.value = instance.value;
        transUnit?.instances.push(inst);
      });
      this.trans_unit.push(transUnit);
    }
  }
  getTranslate(tag, locale, env, args) {
    // megkeressük a megfelelő tag-et, természetesen ha van. clone-t 
    // csinálunk belőle, mert az objektum referencia nekünk itt nem elég
    let unit = Object.assign({}, this.trans_unit.find(u => u.tag === tag));
    // megpróbáljuk feldolgozni a tag-et, a megfelelő paraméterekkel. 
    if (this.testTranslateInstance(unit, locale, args, tag)) {
      return this.processTranslateInstance(unit, locale, args, tag);
    }
    // ha bárhol az instanceok szelektálása közben hibára futunk akkor a végén ide fogunk jutni
    // és csak a tag nevét küldjük vissza
    return '@@' + tag + '@@';
  }
  testTranslateInstance(unit, locale, args, tag) {
    if (unit.hasOwnProperty('instances') && unit.instances.length > 0) {
      const ins = Object.assign({}, unit.instances.find(i => i.locale === locale));
      if (!ins.hasOwnProperty('value')) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
  processTranslateInstance(unit, locale, args, tag) {
    const ins = Object.assign({}, unit.instances.find(i => i.locale === locale));
    this.replaceArgs(ins, args, tag);
    // visszaadjuk a fordításunkat, vagy ha a value nem valid akkor a tag nevét
    return ins.value || '@@' + tag + '@@';
  }
  /**
   * Az args objektumban érkező kulcsokat átfésüljük a fordításon, ha találunk kulcsot
   * akkor azt cseréljük (minden elemet természetesen). Csak akkor csinálunk bármit is,
   * ha az args típusa objektum.
   *
   * @param ins
   * @param args
   */
  replaceArgs(ins, args, tag) {
    if (typeof args === 'object') {
      if (args && args.length > 0) {
        args.forEach(obj => {
          Object.keys(obj).forEach(key => {
            ins.value = ins.value.replace('{' + key + '}', obj[key]);
          });
        });
      } else {
        Object.keys(args).forEach(key => {
          ins.value = ins.value.replace('{' + key + '}', args[key]);
        });
      }
    }
  }
  getTranslateByIndex(cursor) {
    if (this.trans_unit.length > 0) {
      return this.trans_unit[cursor];
    }
    return;
  }
}

/**
 * CoreTranslateService
 *
 * @version 1.3
 * @copyright ScrollMAX Zrt.
 * @author Gabor Ratkai <ratkai.gabor@scrollmax.hu>
 */
class CoreTranslateService {
  /**
   * Konstruktor
   *
   * @param config
   * @param http
   */
  constructor(config, http) {
    this.http = http;
    this._fallbackLocale = 'hu';
    this._currentRequests = [];
    this._registeredToBackend = [];
    this._environment = config;
    this._debug = this._environment.debug || false;
    this._project = this._environment.translate.project;
  }
  /**
   * Speciális funkció, amit az APP INIT során hívunk meg. Ez egy promise, ami végrehajtódik még a teljes
   * rendszer betöltődése előtt.
   */
  init() {
    return this.http.get(`${this._environment.endpoints.locale}/i18/cache?filter[project]=${this._project}`).toPromise().then(res => {
      if (this._debug) {
        console.group('[Core Debug] TranslateService');
        console.log('[Core Debug] Translate promise results:', res);
        console.log('[Core Debug] Cast results:', new TranslateLocale(res));
        console.groupEnd();
      }
      this.setLocaleData(new TranslateLocale(res));
    });
  }
  /**
   * Reseteljük az aktuális request pool-t, a backend felé elküldendő elemeket itt tároljuk (workaround), hogy
   * megakadályozzuk az ismétlődő 'save' küldéseket.
   */
  resetCurrentPage() {
    this._currentRequests = [];
  }
  /**
   * Belső debug módot tudjuk vele bekapcsolni, amely a konzolra ír bizonyos kimeneteket.
   *
   * @param debug
   */
  setDebug(debug) {
    this._debug = debug;
  }
  /**
   * Az aktuálisan használt locale beállítása és a tárolt változók felülírása.
   *
   * @param value
   */
  setCurrentLocale(value) {
    // beseteljük az aktuálisan kapott locale értéket
    localStorage.setItem(this._environment.translate.storage, value);
    // az aktuálisan futó SPA environment változójába is visszaírjuk az alapértelmezett nyelvel
    this.setDefaultLocale(value);
    // ha mindenhol az általunk kívánt nyelv található, akkor true lesz a visszatérés
    return localStorage.getItem(this._environment.translate.storage) === value && this.getDefaultLocale() === value;
  }
  /**
   * Az éppen beállított locale visszaadása (kényelmi funkció)
   */
  getCurrentLocale() {
    return localStorage.getItem(this._environment.translate.storage) ?? this.getDefaultLocale();
  }
  /**
   * Proxy funkció: a teljes tag lista lekérdezése
   *
   * @param resource
   * @deprecated
   */
  fetchTags(resource) {
    return this.fetchLocale(resource);
  }
  /**
   * Egy adott fordítási címke lekérdezése vagy létrehozása amennyiben az nem létezik
   *
   * @param params
   * @param resource
   * @returns
   */
  translateTag(params, resource) {
    if (this._currentRequests.find(r => r.key === params.get('tag')) === undefined) {
      this._currentRequests.push({
        key: params.get('tag')
      });
      // a projectet minden esetben hozzáadjuk a lekérdezéshez
      params = params.set('project', this._project);
      return this.http.post(`${this._environment.endpoints.locale}/i18/translate`, params, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      });
    } else {
      return of();
    }
  }
  /**
   * Megadott tag mentése a paramsban található adatokkal
   *
   * @param params
   * @param resource
   */
  saveTag(params, resource) {
    if (this._currentRequests.find(r => r.key === params.get('tag')) === undefined) {
      this._currentRequests.push({
        key: params.get('tag')
      });
      // a projectet minden esetben hozzáadjuk a lekérdezéshez
      params = params.set('project', this._project);
      return this.http.post(`${this._environment.endpoints.locale}/i18/save`, params, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      });
    } else {
      return of();
    }
  }
  /**
   * Megadott tag törlése
   * @param tag
   * @param resource
   */
  deleteTag(tag, resource) {
    return this.http.delete(`${this._environment.endpoints.locale}/i18/delete/${this._project}/${tag}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    });
  }
  /**
   * Megadott tag mentése a paramsban található adatokkal
   *
   * @param params
   * @param resource
   */
  copyTag(tag, client_id) {
    const _client = this.getDefaultClient(client_id);
    let params = new HttpParams();
    params = params.set('tag', tag);
    params = params.set('nyomda_id', _client.toString());
    params = params.set('project', this._project);
    return this.http.post(`${this._environment.endpoints.locale}/i18/copy`, params, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    });
  }
  /**
   * A backend oldalról kérdezzük le a fordítási adatbázisban található összes fordítási tagot. A fordítási felületünk
   * ezzel az információval fog dolgozni, mert ez a gyorstárazott filevel szemben pontos adatokat ad vissza.
   *
   * @returns
   */
  getTags() {
    return this.http.get(`${this._environment.endpoints.locale}/i18?filter[project]=${this._project}`);
  }
  /**
   * A backend oldalról kérdezzük le egy adott nyomdához és egy adott taghoz tartozó összes instance-t. A fordítási
   * felület ezzel az információval fog dolgozni, mert ez a gyorsítótárazott filevel szemben pontos adatokat ad vissza.
   *
   * @param tag
   * @param client_id
   * @returns
   */
  getTagInstances(tag, client_id) {
    return this.http.get(`${this._environment.endpoints.locale}/i18/instance/${this.getDefaultClient(client_id)}/${this._project}/${tag}`);
  }
  /**
   * A helper funkció segítésével be tudjuk tölteni a service-be az aktuális állomány adatait
   * @param data
   */
  setLocaleData(data) {
    this._data = data;
  }
  /**
   * A helper funkció segítségével a service által tárolt aktuális fordítási állomány
   * adatait tudjuk lekérdezni.
   */
  getLocaleData() {
    return this._data ?? new TranslateLocale();
  }
  /**
   * A helper funkció visszaadja az összes a file-ben tárolt trans unitot.
   * @returns
   */
  getLocaleUnits() {
    return this.getLocaleData().trans_unit;
  }
  /**
   * A helper funkció segítségével visszakapjuk a fordítási egységeinket tartalmazó tömb
   * hosszát, tehát kvázi azt, hogy mennyi rekordunk van az állományban.
   * @returns
   */
  getLength() {
    return this.getLocaleData().trans_unit.length - 1;
  }
  /**
   * A metódusunk a fordítást fogja megoldani. Első lépésben validáljuk a fordítási taget,
   * majd ha az rendelkezésre áll akkor lekérjük a megfelelő instance-t. Ha nincs ilyen
   * fordítási tagunk, akkor elküldjük a backend felé, mert azt rögzítenünk kell!
   *
   * @param tag
   * @param locale
   */
  translate(tag, args, locale) {
    const currentLocale = locale || this.getDefaultLocale();
    if (!this.getLocaleData().validateTranslate(tag) && !this._registeredToBackend.includes(tag)) {
      // ebben az esetben fogunk a backendnek üzenetet küldeni, hogy lett egy új tag-unk
      // és ezt be kellene tenni a json állományba (és az adatbázisba, ha van olyan)
      let params = new HttpParams();
      params = params.set('tag', tag);
      if (args && args?.length > 0) {
        if (args[0]?.locale) {
          params = params.set('locale', args[0].locale);
        }
        if (args[0]?.value) {
          params = params.set('value', args[0].value);
        }
      }
      // regisztráljuk, hogy már elküldtük a backend felé és ezzel megakadályozzuk, hogy 
      // ha töbször van felvéve a tag a sablonban akkor is csak egyszer küldjük el
      this._registeredToBackend.push(tag);
      this.translateTag(params).subscribe();
    }
    // false esetén az eredeti fordítási kulcsot adjuk vissza
    return this.getLocaleData().getTranslate(tag, currentLocale.toUpperCase(), this._environment, args);
  }
  /**
   * Az aktuálisan beállított lokalizációs állomány betöltése a frontendre, amennyiben file nevet is
   * adunk át (relatív path lehetséges! pl. printportal/locales.json) akkor azt fogjuk berántani.
   *
   * @param file
   * @deprecated
   */
  fetchLocale(resource) {
    // visszatérünk egy obszerverrel (get esetében nem kell leíratkozni sem)
    return this.http.get(`${this._environment.endpoints.locale}/translate`);
  }
  /**
   * Kényelmi funkció, amely segítségével lekérdezhetjük az alapértelmezett nyelvi beállítást
   *
   * @returns
   */
  getDefaultLocale() {
    return this._environment.translate.versions.find(v => v.name === this._environment.layout.version).default;
  }
  setDefaultLocale(locale) {
    this._environment.translate.versions.find(v => v.name === this._environment.layout.version).default = locale;
  }
  getDefaultClient(client_id) {
    return client_id || this._environment.layout.client;
  }
  /**
   * Setter a projekt futás időben történő átállításához, alapból nem szükséges a használata, mive egy
   * adott frontend minden esetben az environment állományból veszi ki ezt az adatot, ellenben a translate
   * modulban történő fordítgatás esetén ha több projektet is akarunk kezelni akkor szükség lehet rá.
   *
   * @param project
   */
  setProject(project) {
    this._project = project;
  }
  /**
   * Kényelmi funkció az aktuálisan kezelt projekt lekérdezéséhez
   *
   * @returns
   */
  getProject() {
    return this._project;
  }
  static {
    this.ɵfac = function CoreTranslateService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CoreTranslateService)(i0.ɵɵinject(CORE_TRANSLATE_CONFIG_TOKEN), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CoreTranslateService,
      factory: CoreTranslateService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreTranslateService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CORE_TRANSLATE_CONFIG_TOKEN]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
class TranslatePipe {
  constructor(config, translateService) {
    this.translateService = translateService;
    this._environment = config;
  }
  transform(value, ...args) {
    // kiválasztjuk az éppen aktuális locale-t (ha nincs mentve, akkor az environment default lesz)
    const l = localStorage.getItem(this._environment.translate.storage) || this.translateService.getDefaultLocale();
    // a translate service visszaküldi az aktuális fordítást, vagy ha ilyen nem található akkor az üres label-t
    return this.translateService.translate(value, args, l);
  }
  static {
    this.ɵfac = function TranslatePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TranslatePipe)(i0.ɵɵdirectiveInject(CORE_TRANSLATE_CONFIG_TOKEN, 16), i0.ɵɵdirectiveInject(CoreTranslateService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translate",
      type: TranslatePipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslatePipe, [{
    type: Pipe,
    args: [{
      name: 'translate',
      pure: false
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CORE_TRANSLATE_CONFIG_TOKEN]
    }]
  }, {
    type: CoreTranslateService
  }], null);
})();
class TranslateDirective {
  constructor(config, translateService, renderer, host) {
    this.translateService = translateService;
    this.renderer = renderer;
    this.host = host;
    this._environment = config;
  }
  ngOnInit() {
    // kiválasztjuk az éppen aktuális locale-t (ha nincs mentve, akkor az environment default lesz)
    const l = localStorage.getItem(this._environment.translate.storage) || this.translateService.getDefaultLocale();
    // beszúrjuk a fordítási tagot a hostelementbe
    this.renderer.setAttribute(this.host.nativeElement, 'data-translate', this.tag);
    // csinálunk egy text node-t, ezt fogjuk betoltni a hostelementbe
    let text = this.renderer.createText(this.translateService.translate(this.tag, [this.args], l));
    // appendelünk a renderer segítségével
    this.renderer.appendChild(this.host.nativeElement, text);
  }
  static {
    this.ɵfac = function TranslateDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TranslateDirective)(i0.ɵɵdirectiveInject(CORE_TRANSLATE_CONFIG_TOKEN), i0.ɵɵdirectiveInject(CoreTranslateService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TranslateDirective,
      selectors: [["", "translate", ""]],
      inputs: {
        tag: "tag",
        args: "args"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslateDirective, [{
    type: Directive,
    args: [{
      selector: '[translate]'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CORE_TRANSLATE_CONFIG_TOKEN]
    }]
  }, {
    type: CoreTranslateService
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    tag: [{
      type: Input
    }],
    args: [{
      type: Input
    }]
  });
})();
class CoreTranslateModule {
  static forRoot(config) {
    return {
      ngModule: CoreTranslateModule,
      providers: [{
        provide: CORE_TRANSLATE_CONFIG_TOKEN,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function CoreTranslateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CoreTranslateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CoreTranslateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreTranslateModule, [{
    type: NgModule,
    args: [{
      declarations: [TranslatePipe, TranslateDirective],
      imports: [CommonModule],
      exports: [TranslatePipe, TranslateDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CORE_TRANSLATE_CONFIG_TOKEN, CoreTranslateModule, CoreTranslateService, TranslateDirective, TranslateLocale, TranslateLocaleTransUnit, TranslatePipe };
