import { formatDate } from '@angular/common';
import { Entities } from '@scrollmax/core-nextgen';

export class SpUzenet extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpUzenet';

    kod: number;

    uzenet: string;

    idopont: string;

    felhasznalonev: string;

    pprol: boolean;

    ppuserid: number;

    getKod(): number {
        return this.kod;
    }

    getUzenet(): string {
        return this.uzenet;
    }

    getNev(): string {
        return this.felhasznalonev;
    }

    isOwnMessage(): boolean {
        return (this.pprol === true);
    }

    getNevId(): number {
        return this.ppuserid;
    }

    override getCreatedAt(): string {
        return this.idopont;
    }

    override __toString(): string {
        return this.uzenet;
    }

}
