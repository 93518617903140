import { Entities } from "@scrollmax/core-nextgen";
import { Partner } from "./partner.entity";

export class PartnerCim extends Entities.CoreEntity {
    
    static override kind = 'ScrollMax\\Scroll\\Entity\\Partcimek';

    kod: number;

    partner: Partner = new Partner();

    ceg: string;

    varos: string;

    utca: string;

    isz: string;

    megjegyzes?: string;

    kontakt?: string;

    tel?: string;

    adoszam?: string;

    orszagkod2: string;

    alapertelmezett: boolean;

    partc_tipus: number;

    partc_email?: string;

    /**
     * Rendszerszinten használjuk
     * 
     * @var boolean
     */
    selected: boolean = false;

    getKod(): number {
        return this.kod;
    }

    getVaros(): string {
        return this.varos;
    }

    setVaros(varos: string): PartnerCim {
        this.varos = varos;
        return this;
    }

    getOrszag(): string {
        return this.orszagkod2;
    }

    setOrszag(orszag: string): PartnerCim {
        this.orszagkod2 = orszag;
        return this;
    }

    getUtca(): string {
        return this.utca;
    }

    setUtca(utca: string): PartnerCim {
        this.utca = utca;
        return this;
    }

    getIrszam(): string {
        return this.isz;
    }

    setIrszam(irszam: string): PartnerCim {
        this.isz = irszam;
        return this;
    }

    getMegjegyzes(): string {
        return this.megjegyzes;
    }

    setMegjegyzes(megjegyzes: string): PartnerCim {
        this.megjegyzes = megjegyzes;
        return this;
    }

    getKontakt(): string {
        return this.kontakt;
    }

    setKontakt(kontakt: string): PartnerCim {
        this.kontakt = kontakt;
        return this;
    }

    getTel(): string {
        return this.tel;
    }

    setTel(tel: string): PartnerCim {
        this.tel = tel;
        return this;
    }

    getPartner(): Partner {
        return this.partner;
    }

    getPartnerKod(): number {
        return this.getPartner().getKod();
    }

    getPartnerNev(): string {
        return this.getPartner().getCegnev();
    }
    
    setEgyediCegnev(cegnev: string): PartnerCim {
        this.ceg = cegnev;
        return this;
    }

    getEgyediCegnev(): string {
        return this.ceg;
    }

    setEgyediAdoszam(adoszam: string): PartnerCim {
        this.adoszam = adoszam;
        return this;
    }

    getEgyediAdoszam(): string {
        return this.adoszam;
    }
    
    setAlapertelmezett(alapertelmezett: boolean): PartnerCim {
        this.alapertelmezett = alapertelmezett;
        return this;
    }

    isAlapertelmezett(): boolean {
        return this.alapertelmezett;
    }

    getCimTipus(): number {
        return this.partc_tipus;
    }

    setCimTipus(tipus: number): PartnerCim {
        this.partc_tipus = tipus;
        return this;
    }

    getEmail(): string {
        return this.partc_email;
    }

    setEmail(email: string): PartnerCim {
        this.partc_email = email;
        return this;
    }

    /**
     * Proxy funkció az alapértelmezett érték setterhez
     * 
     * @param def 
     * @returns 
     */
    setDefault(def: boolean): PartnerCim {
        return this.setAlapertelmezett(def);
    }

    /**
     * Proxy funkció az alapértelmezett tulajdonság lekérdezéséhez
     * 
     * @returns 
     */
    isDefault(): boolean {
        return this.isAlapertelmezett();
    }

    isSelected(): boolean {
        return this.selected;
    }

    setSelected(selected: boolean): PartnerCim {
        this.selected = selected;
        return this;
    }

    /**
     * Validáljuk az entitásunkat, ha a forceCountryValidation be van kapcsolva akkor nézzük az országkódot is.
     * 
     * @param entity 
     * @param forceCountryValidation 
     * @returns 
     */
    override validate(entity?: PartnerCim, forceCountryValidation: boolean = false): boolean {

        let countryValid = true;
        let a = entity ?? this;

        if (forceCountryValidation) {
            if (a.orszagkod2?.length !== 2) {
                countryValid = false;
            }
        }

        if (a !== undefined && a !== null && a) {
            return (a.ceg?.length > 0 && a.varos?.length > 0 && a.utca?.length > 0 && a.isz?.length > 0 && countryValid)
        } else {
            return false;
        }

    }

}