import { Entities } from "@scrollmax/core-nextgen";
import { SpLevelsor } from "./sp-levelsor.entity";
import { ILevelParameterTag } from "../../../interfaces/printportal.interfaces";

export class SpLevel extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpLevel';

    kod: number;

    partner: number;

    kelt: string;

    partner_nev: string;

    cimzett_nev: string;

    kuldonev: string;

    felhasznalo_kod: number;

    elso_verzio_cim: string;

    verzio_szam: number;

    tartalom: SpLevelsor[] = [];

    defaults: any;

    csoportok: number[] = [];

    szovegek: unknown;

    rendelesdata: unknown;

    arak: unknown;

    /**
     * Ez a kod a levél kódja! nem a levélfő id. Ez alapján jelenítjük meg a details oldalt.
     * @see ScrollMax\\Scroll\\Entity\\Level
     * 
     * @returns 
     */
    getKod(): number {
        return this.kod;
    }

    /**
     * Az első ajánlat megnevezése, címe, stb.
     * @returns 
     */
    getCim(): string {
        return this.elso_verzio_cim;
    }

    /**
     * Az ajánlat kelte
     * @returns
     */
    getKelt(): string {
        return this.kelt;
    }

    /**
     * Alias az ajánlat keltéhez
     * @returns 
     */
    getDatum(): string {
        return this.getKelt();
    }

    /**
     * Ajánlati verziók száma
     * @returns 
     */
    getVerziok(): number {
        return this.verzio_szam;
    }

    /**
     * Partner név
     * @returns 
     */
    getPartnerNev(): string {
        return this.partner_nev;
    }

    /**
     * Partner kód (id)
     * @returns 
     */
    getPartnerKod(): number {
        return this.partner;
    }

    /**
     * Ajánlat címzettjének a neve
     * @returns 
     */
    getCimzettNev(): string {
        return this.cimzett_nev;
    }

    /**
     * Ajánlat küldőjének a neve
     * @returns 
     */
    getKuldoNev(): string {
        return this.kuldonev;
    }

    /**
     * Levél aktuális tartalma
     * @returns 
     */
    getTartalom(): SpLevelsor[] {
        return this.tartalom as unknown[] as SpLevelsor[];
    }

    /**
     * Alias a levél tartalomhoz
     * @returns 
     */
    getSorok(): SpLevelsor[] {
        return this.getTartalom();
    }

    /**
     * A scrollos user, aki a levelet küldte (teát a küldőnévhez kapcsolódó id)
     * @returns 
     */
    getKuldoKod(): number {
        return this.felhasznalo_kod;
    }

    /**
     * Ha több különböző ajánlat van összefűzve (kitelés) akkor a lehetséges 
     * különböző ajánlatok számát kapjuk meg.
     * @returns 
     */
    getCsoportok(): number[] {
        return this.csoportok;
    }

    /**
     * Az összes backend által összerakott szöveget visszakapjuk (az ajánlathoz)
     * @returns 
     */
    getSzovegek(): unknown {
        return this.szovegek;
    }

    getRendelesAdatok(): unknown {
        return this.rendelesdata;
    }

    getArak(): unknown {
        return this.arak;
    }

    /**
     * A levélben szereplő ajánlat normalizált kódját adjuk vissza
     * @returns 
     */
    getNormalizedOfferCode(): string {

        const regex = /[0-9]+\/[0-9+]+/gm;        
        // Reset `lastIndex` if this regex is defined globally
        // regex.lastIndex = 0;
        
        let m, r;
        while ((m = regex.exec(this.getCim())) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            
            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                if (groupIndex === 0)
                    r = match;
            });
        }


        return r; // parseInt(this.getCim().split('/')[0]);
    }

    /**
     * A levélben szereplő ajánlat normalizált címét adjuk vissza
     * @returns 
     */
    getNormalizedOfferTitle(): string {

        let a = '';
        const t = this.getCim().split('/');
        // darabonként összarakjuk a címet
        t.forEach((parts, index) => {
          if (index > 0) {
            if (index === 1) {
              a += parts.substring(1);
            } else {
              a += parts;
            }    
          }    
        });
    
        return this.clearOfferTitle(a);
    }

    private clearOfferTitle(value: string): string {
        
        // első br cseréje határoló jelre
        value = value.replace(/<br>/i, '|');
        // összes br kivétele a címből
        value = value.replace(/<br>/gi, ' ');
        // határoló jelnél splittelés
        const a = value.split('|');
    
        const l = '<br><span class="label label-light-secondary text-dark font-weight-normal label-inline mt-2">' + a[0] + '</span>'
        
        return (a[1]) ? a[1] + l : a[0];
    }
    
    /**
     * Kényelmi metódus egy tartalom cimke elővételéhez
     * @param sortipus 
     * @param levelFoKod 
     * @returns 
     */
    getTartalomCimke(sortipus: string, levelFoKod: number): string {
        return this.tartalom.find(t => t.sortipus === sortipus && t.scrlev_levelfokod === levelFoKod).cimke;
    }

    /**
     * Kényelmi metódus az összes adatsor eléréséhez
     * @returns 
     */
    getAdatsorok(): SpLevelsor[] {
        return this.tartalom.filter(r => r.sortipus === "adatsor")
    }

    getStatikusTagek(levelFoKod: number): SpLevelsor[] {
        return this.getAdatsorok().filter(r => r.scrlev_adattipus === "" && r.scrlev_levelfokod === levelFoKod);
    }

    getVerzioAdatok(levelFoKod: number): ILevelParameterTag[] {
        return (this.getRendelesAdatok() as ILevelParameterTag[]).find(r => r['verzio'] === levelFoKod)['rendeleshez'];
    }

    /**
     * Kényelmi metódus a levél tartalom hasheléséhez
     * TODO használjuk ezt? valszeg deprecated lesz ez már...
     * @returns 
     */
    processTartalom(): SpLevel {

        this.getTartalom().forEach(s => {
            s.createHash();
        });

        return this;
    }

    override __toString(): string {
        return this.elso_verzio_cim;
    }

    override validate(entity?: SpLevel): boolean {
        throw new Error("Method not implemented.");
    }

}