import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CoreResponse, EntityService } from '@scrollmax/core-nextgen';
import { TokenEntity, UserProfileSimple } from '../entity/core/auth';
import { AppLog } from '../util/log.util';
import { CoreService } from './core.service';

@Injectable({ providedIn: 'root' })
export class InterceptorService {

    /**
     * Ezeket a route-okat nem figyeljük az interceptorral (nem adunk hozzájuk tokent)
     */
    protected athorizedRoutes = [
        'auth/login',
        'auth/forgot-password',
        'auth/registration',
        'drive/download'
    ];

    /**
     * Vizsgáljuk, hogy az éppen elfogott path benne van -e az authorizedRoutes tömbünkben. 
     * Ha igen, akkor az interceptornak false értéket adunk vissza.
     * 
     * @param path 
     * @returns 
     */
    canAuthAndIntercept(path?: string): boolean {

        let intercept = true;
        let current = (path) ? path : window.location.pathname;
        
        if (environment.debug)
            AppLog.message('canAuthAndIntercept path: ' + current);
        
        this.athorizedRoutes.forEach(route => {
            intercept = (current.indexOf(route) > -1) ? false : intercept;
        });

        return intercept;
    }

}