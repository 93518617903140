export const environment = {
    name: 'staging',
    client_id: 0, // nyomda id-nek fogjuk használni
    debug: true,
    debugOptions: {
        logging: true,
        timestamps: true,
        profiler: false,
        pusher: true
    },
    layout: {
      client: 0,
      version: 'generic',
      sidebar: {
        fixed: true
      },
      pagination: {
        prefix: 'ppv3',
        records: {
          changeable: false,
          default: 25,
        },
        maxbuttons: 5
      }
    },
    scrollparams: {
      prefix: 'PPV3_'
    },
    storage: {
        authredirect: 'ppv3-auth-redirect',
        token: 'ppv3-token',
        partner: 'ppv3-partner',
        orderdefs: 'ppv3-order-defs',
        tables: 'ppv3-table-'
    },
    pusher: {
        key: '5ca2257c9d3e46f4a58e',
        cluster: 'eu',
    }, 
    endpoints: {
        auth: 'https://staging.api.printportal.hu', 
        app: 'https://staging.api.printportal.hu', 
        locale: 'https://staging.api.translate.scrollmax.net',
        callas: 'https://staging.api.callas.scrollmax.net',
        fileservice: 'https://staging.api.fileservice.scrollmax.net'
    },
    translate: {
        storage: 'ppv3-locale',
        project: 'pp-v2',
        versions: [
          {
            name: 'generic',
            default: 'HUN',
            locales: [
              'HUN',
              'GBR',
              'DEU'
            ],
            labels: [
              ['HUN', 'Magyar'],
              ['GBR', 'English'],
              ['DEU', 'Deutsch']
            ]
          }
        ]
    }
};
