import { Entities } from "@scrollmax/core-nextgen";

export class SpSzerszam extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpSzerszam';

    /** 
     * Nem fontos nekünk, nem használjuk - placeholder 
     * @var number 
     */
    override id: number;
    
    kod: number;

    nev: string;

    rendeleskod: number;

    cim: string;

    rogzitve: string;

    hatarido: string;

    targy: string;

    fej: string;

    memo: string;

    vege: string;

    kuldonev: string;

    cimzettnev: string;

    parameterek?: { kod: number, cimke: string, adat: string }[] = [];

    fajlkodok?: { id: number, nev: string, fajl_hash: string }[] = [];

    isLejart(): boolean {
        return (new Date(this.hatarido) < new Date());
    }

    fileCount(): number {
        return this.fajlkodok?.length ?? 0;
    }

    parameterCount(): number {
        return this.parameterek?.length ?? 0;
    }

    override validate(entity?: SpSzerszam): boolean {
        return (this.kod > 0);
    }

    override __toString(): string {
        return this.targy;
    }
}