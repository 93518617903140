import { Entities } from "@scrollmax/core-nextgen";

export class ScrollFile extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\File';

    /** 
     * Nem fontos nekünk, nem használjuk - placeholder 
     * @var number 
     */
     override id: number;

    /**
     * Nem használjuk jelenleg, 2-3 karakteres azonosító amit az SM használ
     * @var string
     */
    tipus: string;

    /**
     * @var number
     */
    kod: number;

    /**
     * Az eredeti fájlnév elmentve
     * 
     * @example order_880176d3a68db4503b0f56a4e8b94078.pdf
     * 
     * @var string
     */
    nev: string;

    /**
     * Valószínűleg ez nem lesz fontos nekünk a frontend oldalon, mert itt több minden tárolódhat. Jöhet vissza 
     * full windows path -al maga a file mégegyszer, ezt az SM használja a lokális file -ok eléréséhez. 
     * Jöhet vissza valamilyen szöveg is, valószínűleg ilyen esetben legacy pp - drive - fileservice kombinációról 
     * lehet szó, illetve jöhet vissza gyakorlatilag bármi is.
     * 
     * @example "PrintPortal feltöltés - Megrendelés PDF"
     * @example "https://scrollmax.net/attachment/998/172f9069e2705cb2f4f5778577db14cd.eml"
     * @example "C:\Feladas\0070\ker10ho.pdf"
     * @example "https://scrollmax.net/szallito/998/2019-01-18/3913518b50984d333cb322c223dc0c5f.pdf"
     * 
     * @var string
     */
    file: string;

    /**
     * Megjegyzés a file -hoz
     * @var string
     */
    memo: string;

    /**
     * Feltöltés dátuma
     * @var string
     */
    ido: string;

    /**
     * Feltöltő teljes neve
     * @var string;
     */
    usernev: string;

    /**
     * Az állomány elérhető-e a drive-on, illetve ez már mutatja azt is, hogy kell-e dolgozzunk a 
     * többi mezővel
     * 
     * @var boolean
     */
    fajl_scrdrv: boolean;

    /**
     * @var string
     */
    fajl_hash: string;

    /**
     * @var string
     */
    fajl_slug: string;
    
    /**
     * Kapcsolt file kód. Csak az SM használja, tehát nem igyekszünk klarifikálni mi is ez...
     * 
     * @var number
     */
    fajl_kapcsoltfajlkod: number;

    /**
     * File mérete KB-ban, nem a legjobb de a gettereken átfordítjuk, hogy a byte-os pipe-k tudják használni
     * @var number
     */
    fajl_meret_kb: number;

    /**
     * @var string
     */
    fajl_altipus: string;

    /**
     * Fogalmam nincs, milyen kód lenne ez ha volna
     * 
     * NEED LACI
     * 
     * @returns 
     */
    getKod(): number {
        return this.kod;
    }

    /**
     * A file eredeti neve
     * @returns 
     */
    getName(): string {
        return this.nev;
    }

    /**
     * Megjegyzés a file mellé
     * @returns 
     */
    getDescription(): string {
        return this.memo;
    }

    /**
     * Feltöltés dátuma
     * @returns 
     */
    getDate(): string {
        return this.ido;
    }

    /**
     * Feltöltő neve
     * @returns 
     */
    getUploaderName(): string {
        return this.usernev;
    }

    /**
     * A metódus megmondja nekünk, hogy az entitás adatai alapján elérhető -e a fileservice letöltés 
     * @returns 
     */    
    isFileserviceAvailable(): boolean {
        return (this.fajl_hash !== '' && this.fajl_hash !== undefined);
    }

    /**
     * A metódus megmondja nekünk, hogy az entitás adatai alapján elérhető -e a drive letöltés 
     * @returns 
     */
    isDriveAvailable(): boolean {
        return (this.fajl_scrdrv && this.fajl_slug !== '' && this.fajl_slug !== undefined);
    }

    /**
     * Az állomány fileservice hash értéke, ez még nem jelenti azt, hogy drive slugja is van.
     * @returns
     */
    getFileserviceHash(): string {
        return this.fajl_hash;
    }

    /**
     * Az állomány scroll drive slug értéke
     * @returns 
     */
    getDriveSlug(): string {
        return this.fajl_slug;
    }

    /**
     * A file mérete byte -ban
     * @returns 
     */
    getSize(): number {
        return this.fajl_meret_kb * 1024;
    }

    /**
     * Speciális szöveges flag, gyakorlatilag azt mutatja meg, hogy mi is ez.
     * 
     * @example "pp_feltoltes"
     * 
     * @returns 
     */
    getType(): string {
        return this.fajl_altipus;
    }


    override __toString(): string {
        throw new Error("Method not implemented.");
    }
    
    override validate(entity?: ScrollFile): boolean {
        throw new Error("Method not implemented.");
    }
}