import { Entities } from "@scrollmax/core-nextgen";

export class SpLevelsor extends Entities.CoreEntity {
    
    static override kind = 'ScrollMax\\Scroll\\Entity\\SpLevelsor';

    levelsor_kod: number;

    sortipus: string;

    cimkeclass: string;

    adatclass: string;

    cimke: string;

    adat: string;

    scrlev_levelfokod: number;

    scrlev_adattipus: string;

    ajanlat!: number;

    // meta mezők az adatsor kezeléshez (csak adatsoroknál játszik)

    /**
     * Az alap ajánlathoz képest változtatott érték
     * @var boolean 
     **/
    _changed: boolean = false;

    /** 
     * Az aktuális ajánlati verzióban nem használ érték (nem kell megjeleníteni, de debugként esetleg ez alapján meg tudjuk nézni mit szedtünk ki)
     * @var boolean 
     **/
    _removed: boolean = false;

    /** @var string */
    _valueOld: string = '';

    /** @var string */
    _valueNew: string = '';

    /**
     * Verzió indexeléshez használjuk
     * @var number
     */
    _index: number = 0;

    /**
     * @var string
     */
    _uid: string;
    
    /**
     * Főverziók szétválasztásához használjuk
     * @var string
     */
    _hash: string;

    getKod(): number {
        return this.levelsor_kod;
    }

    getAjanlatKod(): number {
        return this.ajanlat;
    }

    isAjanlat(): boolean {
        return (this.ajanlat !== null && this.ajanlat !== undefined && this.ajanlat > 0);
    }

    getCimke(): string {
        return this.cimke;
    }

    getAdat(): string {
        return this.adat;
    }

    getTipus(): string {
        return this.getSorTipus();
    }

    getSorTipus(): string {
        return this.sortipus;
    }

    getCimkeClass(): string {
        return this.cimkeclass;
    }

    getAdatClass(): string {
        return this.adatclass;
    }

    getLevelFoKod(): number {
        return this.scrlev_levelfokod;
    }

    getAdatTipus(): string {
        return this.scrlev_adattipus;
    }

    isRemoved(): boolean {
        return this._removed;
    }

    setRemoved(value: boolean): SpLevelsor {
        this._removed = value;
        return this;
    }

    isChanged(): boolean {
        return this._changed;
    }

    setChanged(value: boolean): SpLevelsor {
        this._changed = value;
        return this;
    }

    getOldValue(): string {
        return this._valueOld;
    }

    setOldValue(value: string): SpLevelsor {
        this._valueOld = value;
        return this;
    }

    getNewValue(): string {
        return this._valueNew;
    }

    setNewValue(value: string): SpLevelsor {
        this._valueNew = value;
        return this;
    }

    setVerzioIndex(value: number): SpLevelsor {
        this._index = value;
        return this;
    }

    getVerzioIndex(): number {
        return this._index;
    }

    setUuid(value: string): SpLevelsor {
        this._uid;
        return this;
    }

    getUuid(): string {
        return this._uid;
    }

    setHash(value: string): SpLevelsor {
        this._hash = value;
        return this;
    }

    getHash(): string {
        return this._hash;
    }

    /**
     * Megfelelő sortípus esetén készítünk egy hash-t, ami gyakorlatilag az esetünkben az ajánlati főverziót fogja jelenteni
     * @returns 
     */
    createHash(): SpLevelsor {

        if (this.getSorTipus() === 'cimsor') {
            const regex = /([0-9]+)\/([0-9]+)\s(.*)/gm;
            // const str = `253413/10 Társasjáték - Definity - 144 lap/játék`;
            let m;
        
            while ((m = regex.exec(this.getCimke())) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                        
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                if (groupIndex === 3) this.setHash(this.b64(match));                
                });
            }           
        }

        return this;
    }

    private b64(value: string): string {
        return btoa(unescape(encodeURIComponent(value)));
    }

    override __toString(): string {
        return this.adat;
    }

    override validate(entity?: SpLevelsor): boolean {
        throw new Error("Method not implemented.");
    }


}