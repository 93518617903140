export class UploadProgress {

    protected name: string;
    
    protected progress: number;

    constructor(name: string) {
        this.name = name;
        this.progress = 0;
    }

    getName(): string {
        return this.name;
    }

    setProgress(progress: number): void {
        this.progress = progress;
    }

    getProgress(): number {
        return this.progress;
    }

    isStarted(): boolean {
        return (this.progress > 0);
    }

    isCompleted(): boolean {
        return (this.progress >= 100);
    }

}