import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CoreResponse, EntityService } from '@scrollmax/core-nextgen';
import { TokenEntity, UserProfileSimple } from '../entity/core/auth';
import { AppLog } from '../util/log.util';
import { CoreService } from './core.service';
import { InterceptorService } from './interceptor.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ITokenData } from '../interfaces/core.interfaces';
import { PusherService } from './pusher.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  isFirstLoading = true;

  userProfile: ReplaySubject<UserProfileSimple> = new ReplaySubject();

  userActive: UserProfileSimple | undefined;

  view = 'preload';
  
  constructor(
    private http: HttpClient, 
    private entityService: EntityService,
    private interceptorService: InterceptorService,
    private coreService: CoreService,
    private pusherService: PusherService
  ) { 
    this.onReload();
  }

  /**
   * Meghívjuk a bejelentkezés végpontot a kapott adatokkal. A visszatérésünk egy TokenEntity lesz, mert ez egy speciális végpont.
   *
   * @param email string
   * @param password string
   * @returns
   */
  onLogin(email: string, password: string): Observable<TokenEntity> {

    let params: HttpParams = new HttpParams();
    params = params.set('email', email);
    params = params.set('password', password);
    params = params.set('client', environment.client_id.toString());
    
    return this.http.post<TokenEntity>(`${environment.endpoints.app}/auth/password-token/printportal`, params, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    });
  }

  /**
   * Lekérdezünk egy user profile objektumot az auth szervertől
   * 
   * @returns 
   */
  onUserProfile(): Observable<Object> {

    AppLog.log('Current token', localStorage.getItem(environment.storage.token));

    return this.http.get(`${environment.endpoints.auth}/auth/info`, { 
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem(environment.storage.token)) 
    });

  }

  onReload(): void {
    
    const token = localStorage.getItem(environment.storage.token);
    
    if (token && this.interceptorService.canAuthAndIntercept()) {    
      
      AppLog.log('[DEBUG] Reloading and fetching user profile');

        this.onUserProfile().subscribe({ 
          next: (r: Object) => {

            // OAuth user profile objektumot fogunk visszakapni
            const profile = r as UserProfileSimple;

            AppLog.log('Reload completed, setting profile object', profile);

            // seteljük az aktuális munkamenetben a behaviorsubjectünknek
            this.userProfile.next(profile);
            this.userActive = profile;

            // pusher service initek
            try {
              this.pusherService.initService(profile.id);   
            } catch (e) {
              AppLog.log('Pusher service initialization failed', [e.message]);
            }          

          },
          error: (err) => {
            
            AppLog.log('Reload failed, destroying profile object', err);
            
            this.userProfile.next(null);
            this.userActive = undefined;

          }
        });

    }

  }

  /**
   * A kapott user profilt tároljuk a replay subjectben
   * 
   * @param profile 
   */
  setProfile(profile: UserProfileSimple): void {
    this.userProfile.next(profile);
    this.userActive = profile;
  }

  /**
   * Visszakapjuk az aktív felhasználót
   * 
   * @returns 
   */
  getProfile(): UserProfileSimple | undefined {
    return this.userActive;
  } 

  /**
   * Kényelmi metódus a profil replay subject eléréséhez
   * 
   * @returns 
   */
  observeProfile(): ReplaySubject<UserProfileSimple> {
    return this.userProfile;
  }

  /**
   * A kapott tokent tároljuk a localStorage-ben
   * 
   * @param token 
   */
  setToken(token: string): void {
      localStorage.setItem(environment.storage.token, token);
  }

  /**
   * Elküldünk egy elvesztett jelszó requestet a szerver felé
   * 
   * @param params 
   * @returns 
   */
  public onForgotPassword(params: HttpParams): Observable<any> {
    return this.http.post(`${environment.endpoints.app}/nyomdauser/forgot`, params);
  }

  /**
   * Elküldünk egy jelszó változtatásra vonatkozó requestet a szerver felé
   * 
   * @param params 
   * @returns 
   */
  public onChangePassword(params: HttpParams): Observable<any> {
    return this.http.post(`${environment.endpoints.app}/nyomdauser/password`, params);
  }

  /**
   * Kiléptetjük a felhasználót a rendszerből
   */
  public onLogout(): void {
    
    // ha nem tölti újra az oldalt csak kilép, akkor ezt a flag-et be kell állítsuk.
    this.isFirstLoading = true;
    
    // a tokent mindenképpen töröljük mert az a cél, hogy teljesen új tokennel menjen a felhasználó
    localStorage.removeItem(environment.storage.token);

    // reseteljük az user-t, mert különben a login komponensben a redirectelés végtelen loopba kerül
    this.userProfile.next(null);
  }

  /**
   * Kényelmi metódus a speciális fejlécek hozzáadásához
   * 
   * @returns 
   */
  addPostOptions(): HttpHeaders {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem(environment.storage.token));

    return headers;
  }


  /**
   * Lekezeljük, ha probléma volt a lekérdezéssel
   *
   * @param operation string
   * @param result any
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {

      if (error instanceof HttpErrorResponse) {
        if (error.hasOwnProperty('error')) {
          result = error.error;
        }
      }

      AppLog.log('General Exception:', error);

      // hagyjuk futni az appot, azzal az értékkel amit kapunk a resultban
      return of(result);
    };
  }

  /**
   * Ellenőrizzük, hogy a felhasználó tokenjében megvannak -e azok a scopeok, amelyek 
   * az oldal, erőforrás, stb. betöltéséhez kellenek
   * 
   * @param scopes 
   */
  isUserScopesAvailable(scopes: string[]): boolean {

    let ret = true;
    const availableScopes = AuthService.getCurrentDecodedToken()?.scope.split(' ');
    scopes.forEach((scope: string) => {
      if (!availableScopes.includes(scope)) {
        ret = false;
      }
    });

    return ret;
  }

  /**
   * Statikus helper funkció a scope ellenőrzéshez, a localstorageben tárolt tokenen végzünk el ellenőrzést.
   * 
   * @param scopes 
   * @returns 
   */
  static isScopeAvailable(scopes: string[]): boolean {

    let ret = true;
    const availableScopes = AuthService.getCurrentDecodedToken()?.scope.split(' ');
    scopes.forEach((scope: string) => {
      if (!availableScopes.includes(scope)) {
        ret = false;
      }
    });

    return ret;
  }

  /**
   * Statikus helper funkció a scope ellenőrzéshez, akkor adunk vissza true értéket ha a scope NINCS 
   * meg a felhasználó scope-k között. 
   * 
   * @param scope 
   * @returns 
   */
  static isScopeUnavailable(scope: string): boolean {
    return (AuthService.getCurrentDecodedToken()?.scope.split(' ').includes(scope)) ? false : true;
  }

  /**
   * Kényelmi funkció az aktuális token feldolgozásához
   */
  static getCurrentDecodedToken(): ITokenData {

    const token = localStorage.getItem(environment.storage.token);    
    const helper = new JwtHelperService();

    return helper.decodeToken(token) as unknown as ITokenData;
  }

  /**
   * Kényelmi funkció a token expirációs idejének eléréséhez
   * @returns 
   */
  static getTokenExpires(): number {
    return AuthService.getCurrentDecodedToken()?.exp * 1000;
  }

  /**
   * Kényelmi funkció a felhasználóhoz regisztrált scopeok eléréséhez (token alapján)
   * 
   * @returns 
   */
  getUserCurrentScopes(): string[] {
    return AuthService.getCurrentDecodedToken()?.scope.split(' ');
  }

}
