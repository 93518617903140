import { Entities } from "@scrollmax/core-nextgen";

export class Termekdij extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\Termekdij';

    /** 
     * Nem fontos nekünk, nem használjuk - placeholder 
     * @var number 
     */
    override id: number;

    /**
     * @var number
     */
    kod: number;

    /**
     * @var string
     */
    nev: string;

    /**
     * @var boolean
     */
    nyilatkozatkell: boolean = false;

    getKod(): number {
        return this.kod;
    }

    getNev(): string {
        return this.nev;
    }

    getNyilatkozat(): boolean {
        return this.nyilatkozatkell;
    }

}