import { signal, WritableSignal } from "@angular/core";
import { Entities } from "@scrollmax/core-nextgen";
import { IPreflightPageError } from "../../../interfaces/printportal.interfaces";

export class SpTaskaFile extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpTaskaFile';

    fajl_kod: number;

    nev: string;

    file: string;

    usernev: string;

    fajl_hash: string = null;

    fajl_slug: string = null;

    fajl_meret_kb: number;

    memo: string;

    extension: string;

    realname: string;

    ido: string;

    report_hash: string;

    visszajelzeskell: WritableSignal<boolean> = signal<boolean>(false);

    // callas async feldolgozás kezeléséhez bekerült kényelmi metódusok és tulajdonságok
    preflightState = signal<'error' | 'waiting' | 'preflighting' | 'finished' | string>('waiting');

    onChangePreflightState(state: string): void {
        this.preflightState.set(state);
    }

    // callas miatt bekerült mezők

    isPreflightAvailable(): boolean {
        return (this.oldal_db > 0);
    }

    error_db: number = 0;

    getCallasErrors(): number {
        return (this.error_db > 0) ? this.error_db : 0;
    }

    warning_db: number = 0;

    getCallasWarnings(): number {
        return (this.warning_db > 0) ? this.warning_db : 0;
    }

    info_db: number = 0;

    getCallasInfos(): number {
        return (this.info_db > 0) ? this.info_db : 0;
    }

    oldal_db: number = 0;

    getCallasPages(): number {
        return (this.oldal_db > 0) ? this.oldal_db : 0;
    }

    oldal_error_db: number = 0;

    getCallasPageErrors(): number {
        return (this.oldal_error_db > 0) ? this.oldal_error_db : 0;
    }

    oldal_warning_db: number = 0;

    getCallasPageWarnings(): number {
        return (this.oldal_warning_db > 0) ? this.oldal_warning_db : 0;
    }

    oldal_info_db: number = 0;

    getCallasPageInfos(): number {
        return (this.oldal_info_db > 0) ? this.oldal_info_db : 0;
    }

    hibak: IPreflightPageError[];

    getCallasErrorObjects(): IPreflightPageError[] {
        return this.hibak;
    }

    szinek: string;

    getCallasColorTexts(): string[] {
        return this.szinek.split(',');
    }

    szinek_db: number = 0;

    getCallasColors(): number {
        return (this.szinek_db > 0) ? this.szinek_db : 0;
    }

    oldalkepek: unknown[];

    getCallasPageImages(): unknown[] {
        return this.oldalkepek;
    }

    // eredeti getterek

    getKod(): number {
        return this.fajl_kod;
    }

    getNev(): string {
        return this.nev;
    }

    getRealName(): string {
        return this.realname;
    }

    getFileNev(): string {
        return this.file;
    }

    getFeltoltoNev(): string {
        return this.usernev;
    }

    getHash(): string {
        return this.fajl_hash;
    }

    isPreflightHash(): boolean {
        return (this.report_hash != '' && this.report_hash !== null);
    }

    getPreflightHash(): string {
        return (this.report_hash != '') ? this.report_hash : '';
    }

    getSlug(): string {
        return this.fajl_slug;
    }

    getSizeInKiloBytes(): number {
        return this.fajl_meret_kb;
    }

    getSizeInBytes(): number {
        return this.fajl_meret_kb * 1024;
    }

    getSizeInBytesString(): string {
        return (this.fajl_meret_kb * 1024).toString();
    }

    getMegjegyzes(): string {
        return this.memo;
    }

    getExtension(): string {
        return this.extension;
    }

    getUploadDate(): string {
        return this.ido;
    }

    isDownloadable(): boolean {
        return (this.getSlug() !== null && this.getHash() !== null);
    }

    isVisszajelzesKell(): boolean {
        return this.visszajelzeskell();
    }

    override __toString(): string {
        throw new Error("Method not implemented.");
    }

    override validate(entity?: SpTaskaFile): boolean {
        throw new Error("Method not implemented.");
    }

}
