import { Entities } from '@scrollmax/core-nextgen';

export class Nyomda extends Entities.CoreEntity {

    public static override kind: string = 'ScrollMax\\Core\\Db\\Entity\\Nyomda';

    nev: string;

    getName(): string {
        return this.nev;
    }

}