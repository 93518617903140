import { environment } from "../../../environments/environment";

export class AppLog {
   
    public static log(message: string, ...args): void {
        
        if (environment.debug && environment.debugOptions.logging) {
            console.log('[DEBUG] %s', message, args);  
        }               

    } 

    public static message(message: string): void {
        
        if (environment.debug && environment.debugOptions.logging) {
            console.log('[DEBUG] %s', message);  
        }               

    } 

}