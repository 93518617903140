import { Entities } from "@scrollmax/core-nextgen";
import { Partner } from "./partner.entity";

// pp jogokhoz interface
import { IScrollPermission } from "../../interfaces/scroll.interfaces";

export class Nev extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\Nev';
   
    kod: number;

    nev: string;

    beosztas: string;

    tel: string;

    tel2: string;

    mobil: string;

    email: string;

    portalkod?: number;

    jogok?: IScrollPermission[] = [];

    /**
     * @var Partner
     */
    partner?: Partner;

    /**
     * Rendszerszinten használjuk
     * 
     * @var boolean
     */
    selected: boolean = false;

    static columnToggleReference(): string[] {
        return [
            'beosztas',
            'email',
            'partner',  // virtuális mező
            'telefon',  // virtuális mező
        ];
    }

    static getScrollPermissionDetails(): IScrollPermission[] { 

        const details: IScrollPermission[] = [];
        details.push({ label: 'pp_jog_ajanlatok', value: false, description: 'pp_jog_ajanlatok_details', icon: 'ki-abstract-26', beta: false });
        details.push({ label: 'pp_jog_anyagleadas', value: false, description: 'pp_jog_anyagleadas_details', icon: 'ki-cloud', beta: false });
        details.push({ label: 'pp_jog_cegadatok', value: false, description: 'pp_jog_cegadatok_details', icon: 'ki-setting-4', beta: false });
        details.push({ label: 'pp_jog_eszamla', value: false, description: 'pp_jog_eszamla_details', icon: 'ki-medal-star', beta: false });
        details.push({ label: 'pp_jog_imprimalas', value: false, description: 'pp_jog_imprimalas_details', icon: 'ki-color-swatch', beta: false });
        details.push({ label: 'pp_jog_szamlak', value: false, description: 'pp_jog_szamlak_details', icon: 'ki-cheque', beta: false });
        details.push({ label: 'pp_jog_taskak', value: false, description: 'pp_jog_taskak_details', icon: 'ki-briefcase', beta: false });
     
        return details;
    }

    override setId(id: number): Nev {
        this.id = id;
        return this;
    }

    getKod(): number {
        return this.kod;
    }

    setKod(kod: number): Nev {
        this.kod = kod;
        return this;
    }

    getNev(): string {
        return this.nev;
    }

    setNev(nev: string): Nev {
        this.nev = nev;
        return this;
    }

    getBeosztas(): string {
        return this.beosztas;
    }

    setBeosztas(beosztas: string): Nev {
        this.beosztas = beosztas;
        return this;
    }

    getTel(): string {
        return this.tel;
    }

    setTel(tel: string): Nev {
        this.tel = tel;
        return this;
    }

    getTel2(): string {
        return this.tel2;
    }

    setTel2(tel2: string):  Nev {
        this.tel2 = tel2;
        return this;
    }

    getMobil(): string {
        return this.mobil;
    }

    setMobil(mobil: string): Nev {
        this.mobil = mobil;
        return this;
    }

    getEmail(): string {
        return this.email;
    }

    setEmail(email: string): Nev {
        this.email = email;
        return this;
    }

    isSelected(): boolean {
        return this.selected;
    }

    setSelected(selected: boolean): Nev {
        this.selected = selected;
        return this;
    }

    /**
     * Shortcut a partnerhez
     * 
     * @returns 
     */
    getPartner(): Partner | undefined {
        return this.partner;
    }

    /**
     * Kényelmi funkció a partner kód eléréséhez
     * 
     * @returns 
     */
    getPartnerKod(): number {
        return this.partner?.getKod() ?? 0;
    }

    /**
     * Kényelmi funkció a partner név eléréséhez
     * 
     * @returns 
     */
    getPartnerNev(): string {
        return this.partner?.getCegnev() ?? '';
    }

    getPortalkod(): number {
        return this.portalkod;
    }

    isPortalkod(): boolean {
        return (this.portalkod && !isNaN(this.portalkod)) ? true : false;
    }

    getJogok(): IScrollPermission[] {
        return this.jogok;
    }

    getPErmissions(): IScrollPermission[] {
        return this.getJogok();
    }

    parsePermissions(): Nev {

        const props = Object.keys(this);
        props.forEach((p: string) => {
            if (p.indexOf('pp_jog') > -1) {

                try {
                    // kikeressük a statikus metóduson keresztül
                    const permission = Nev.getScrollPermissionDetails().find(j => j.label === p);

                    // appendeljük az entitás értékét
                    permission.value = this[p];

                    // beírjuk az entitásunkba
                    this.jogok.push(permission);
                } catch (e) {}

            }
                
        });

        return this;
    }

    /**
     * Validáljuk az aktuális entitásunkat, amelyben a nevet a tel-t vagy a mobilt és az e-mail 
     * címet írjuk elő kötelezően. Ezen adatok hiányában az entitásunk invalid lesz.
     * 
     * @param entity 
     * @returns 
     */
    override validate(entity?: Nev): boolean {

        let a = entity ?? this;

        if (a !== undefined && a !== null && a) {
            return (a.nev?.length >= 5 && a.mobil?.length >= 8 && a.email?.length > 0)
        } else {
            return false;
        }
        
    }

    override __toString(): string {
        return this.getNev();
    }

}