import { Entities } from "@scrollmax/core-nextgen";
import { IScrollChatHeads, IScrollJogok, IScrollParams, IScrollWebAllapotok } from "../../interfaces/scroll.interfaces";

export class PreloadEntity extends Entities.CoreEntity {

    public static override kind: string = 'stdClass';
   
    weballapotok: IScrollWebAllapotok[] = [];

    params: IScrollParams[] = [];

    chatfejek: IScrollChatHeads[] = [];

    jogok: IScrollJogok[] = [];

}