import { Entities } from '@scrollmax/core-nextgen';

export class UserProfileSimple extends Entities.CoreEntity {

    public static override kind: string = 'stdClass';

    firstname: string;

    lastname: string;

    scope: string;

    email: string;

    getName(): string {
        return this.lastname + ' ' + this.firstname;
    }

    setFirstName(firstname: string): void {
        this.firstname = firstname;
    }

    getFirstName(): string {
        return this.firstname;
    }

    setLastName(lastname: string): void {
        this.lastname = lastname;
    }

    getLastName(): string {
        return this.lastname;
    }

    setEmail(email: string): void {
        this.email = email;
    }

    getEmail(): string {
        return this.email;
    }

    setScopes(scope: string): void {
        this.scope = scope;
    }

    getScopes(): string[] {
        return this.scope.split(' ');
    }
 
}
