export class PrintportalOrder {

    levelfo: number;

    cim: string;

    po?: string;

    cikkszam?: string;

    isbn?: string;

    megjegyzes?: string;
    
    /**
     * Anyagleadás dátuma pl. 2025-02-28
     */
    anyagle: string;

    /**
     * Anyagleadás dátum megjegyzés pl. reggel
     */
    anyagleido: string;

    /**
     * Anyagleadó név kódja
     */
    anyagle_nev: number;

    /**
     * Belső változó, ezt nem fogjuk validálni. Az anyagleadás és a határidő közötti minimum dátumkülönbség napokban
     */
    anyagle_atfutas: number = 0;

    /**
     * Anyagleadó név text, debug ágba kell kerüljün, nem használjuk validációra
     */
    anyagle_nev_text?: string;

    /**
     * Szállítási határidő dátuma pl. 2025-03-10 (ez gyakorlatilag a sima határidő mező)
     */
    szallitas: string;

    /**
     * Szállítási határidő dátum megjegyzés pl. este
     */
    szallido?: string;

    /**
     * Jóóváhagyást kér - nem kér (alapértelmezésben false) (softproof)
     */
    imprimalas_korrektura: boolean = false;

    /**
     * Jóváhagyás megjegyzés (softproof)
     */
    imprimalas_megjegyzes?: string;

    /**
     * Jóváhagyó név kódja (softproof)
     */
    imprimalas_nev?: number;

    /**
     * Jóváhagyó név text, debug ágba kell kerüljün, nem használjuk validációra
     */
    imprimalas_nev_text?: string;

    /**
     * Jóváhagyás options 
     * TODO csak keskenynél volt, kell ez?
     */
    imprimalas_proofoptions?: number;

    /**
     * Gépindulást kér - nem kér
     */
    gepindulas_jelen: boolean = false;

    /**
     * Gépindulás megjegyzés
     */
    gepindulas_megjegyzes?: string;

    /**
     * Gépindulás név kód
     */
    gepindulas_nev?: number;

    /**
     * Gépindulás név text, debug ágba kell kerüljün, nem használjuk validációra
     */
    gepindulas_nev_text?: string;

    /**
     * Kiváálasztott termékdíj kategória kódja
     */
    termekdij: number;

    /**
     * Számlázási cím kódja
     */
    szamlacim: number;

    /**
     * Szállítási cím kódja
     */
    szallitasicim: number;

    /**
     * Személyes átvételt kér - nem kér
     */
    szemelyesatvetel: boolean = false;

    /**
     * Megrendelési feltételek
     */
    mfeltetelek: boolean = false;

    /**
     * ÁSZF feltétek
     */
    maszf: boolean = false;

    /**
     * Extra ÁSZF
     */
    easzf: boolean = false;

    /**
     * Szállítási dátum (Y-m-d)
     * TODO ez mi? utánanézni...
     */
    szallitasi_ido: string;

    /**
     * Ezt is küldjük, mert ha a rendelés paraméterek megváltoztak akkor azt rögzíteni kell
     */
    rendeles_adat_changed: boolean = false;

    rendeles_adat: unknown[] = [];

    protected errors: { name: string, error: string }[] = [];

    protected _required: string[] = [
        'levelfo',
        'cim',
        'anyagle',
        'anyagle_nev',
        'szallitas',
        'termekdij',
        'szamlacim',
        'szallitasicim'
    ];

    /**
     * Általános validáció mindenre, ami elméletileg kötelező
     * @returns 
     */
    validate(): boolean {

        let valid = true;
        this._required.forEach(key => {
            if (!this[key]) {
                this.addError(key, 'field is required');
                valid = false;
            } else {
                this.removeError(key);
            }
        });

        this.validateImprimalas();

        return valid;
    }

    /**
     * Belső validáció az imprimálásra, semmiképpen nem küldünk névkódot, ha az imprimálást kér ki van kapcsolva!
     */
    private validateImprimalas(): void {
        if (!this.isImprimalas()) {
            delete this.imprimalas_nev;
            delete this.imprimalas_nev_text;
        }
    }

    /**
     * Belső validáció az gépindulásra, semmiképpen nem küldünk névkódot, ha a gépindulást kér ki van kapcsolva!
     */    
    private validateGepindulas(): void {
        if (!this.isGepindulas()) {
            delete this.gepindulas_nev;
            delete this.gepindulas_nev_text;
        }
    }

    isParametersChanged(): boolean {
        return this.rendeles_adat_changed;
    }

    /**
     * Kényelmi metódus a személyes átvétel kezeléséhez. Sablonozáskor jól jön, ha nem kell tulajdonságnévvel dolgozni.
     * @returns 
     */
    isSzemelyesAtvetel(): boolean {
        return this.szemelyesatvetel;
    }

    /**
     * Kényelmi metódus az gépindulás lekérdezéséhez (kér, nemkér). Sablonozáskor jól jön, ha nem kell tulajdonságnévvel dolgozni.
     * @returns 
     */
    isGepindulas(): boolean {
        return this.gepindulas_jelen;
    }

    /**
     * Kényelmi metódus az gépindulással kapcsolatos tulajdonságok alaphelyzetbe állításához. A nevet nem változtatjuk az 
     * alapértelmezések miatt, de legkésőbb a validáció során a belső validátor törli, ha nem kell.
     */
    resetFeatureGepindulas(): void {
        delete this.gepindulas_megjegyzes;
    }

    /**
     * Kényelmi metódus az imprimálás lekérdezéséhez (kér, nemkér). Sablonozáskor jól jön, ha nem kell tulajdonságnévvel dolgozni.
     * @returns 
     */
    isImprimalas(): boolean {
        return this.imprimalas_korrektura;
    }

    /**
     * Kényelmi metódus az imprimálással kapcsolatos tulajdonságok alaphelyzetbe állításához. A nevet nem változtatjuk az 
     * alapértelmezések miatt, de legkésőbb a validáció során a belső validátor törli, ha nem kell.
     */
    resetFeatureImprimalas(): void {
        delete this.imprimalas_megjegyzes;
        delete this.imprimalas_proofoptions;
    }

    /**
     * Felveszünk egy hibát a rendeléshez
     * @param name 
     * @param error 
     * @returns 
     */
    addError(name: string, error: string): PrintportalOrder {

        if (this.errors.findIndex(e => e.name === name) === -1)
            this.errors.push({ name, error });

        return this;
    }

    /**
     * Törlünk egy hibát a rendelésből
     * @param name 
     * @returns 
     */
    removeError(name: string): PrintportalOrder {

        const index = this.errors.findIndex(e => e.name === name);
        if (index) {
            this.errors.splice(index, 1);
        }

        return this;
    }

    /**
     * Visszaadjuk az összes hibát, ami éppen regisztrálva van
     * @returns 
     */
    getErrors(): { name: string, error: string }[] {
        return this.errors;
    }

}

export class OrderDataField {

    adat: string;    
    cimke: string;
    kod: number;
    scrlev_adattipus: string;
    tagkod: number;
    tipus: string;
    editable = false;

    // arra fogjuk használni, hogy HA szerkesztették a rendelés formon de kitalálják, hogy 
    // mégsem akarják, akkor vissza tudjuk állítani az értéket alapállípotra
    readonly adat_original: string;

    constructor(adat: string) {
        this.adat_original = adat;
    }

    resetAdat(): OrderDataField {
        this.adat = this.adat_original;
        return this;
    }

    setAdat(adat: string): OrderDataField {
        this.adat = adat;
        return this;
    }

    getAdat(): string {
        return this.adat;
    }

    setCimke(cimke: string): OrderDataField {
        this.cimke = cimke;
        return this;
    }

    getCimke(): string {
        return this.cimke;
    }

    setKod(kod: number): OrderDataField {
        this.kod = kod;
        return this;
    }

    getKod(): number {
        return this.kod;
    }

    setAdattipus(scrlev_adattipus: string): OrderDataField {
        this.scrlev_adattipus = scrlev_adattipus;
        return this;
    }

    getAdattipus(): string {
        return this.scrlev_adattipus;
    }

    setTagKod(tagkod: number): OrderDataField {
        this.tagkod = tagkod;
        return this;
    }

    getTagKod(): number {
        return this.tagkod;
    }

    /**
     * Szerkeszthető tag-ekhez használjuk
     * @returns 
     */
    getEgyediAdattipus(): string {
        return this.scrlev_adattipus + '_' + this.kod.toString();
    }

    setMezoTipus(tipus: string): OrderDataField {
        this.tipus = tipus;
        return this;
    }

    getMezoTipus(): string {
        return this.tipus;
    }

    setEditable(editable: boolean): OrderDataField {
        this.editable = editable;
        return this;
    }

    isEditable(): boolean {
        return this.editable;
    }
    
}

export class OrderParameterField {

    label: string;

    name: string;

    description: string;

    value: string | string[];

    code?: number;

    type: string;

    static?: boolean = false;

    freshvalue?: string;

    question?: boolean = false;

    required = false;

    setRequired(value: boolean): OrderParameterField {
        this.required = value;
        return this;
    }

    isRequired(): boolean {
        return this.required;
    }

    setQuestion(value: boolean): OrderParameterField {
        this.question = value;
        return this;
    }

    isQuestion(): boolean {
        return this.question;
    }

    setLabel(value: string): OrderParameterField {
        this.label = value;
        return this;
    }

    getLabel(): string {
        return this.label;
    }

    setDescription(description: string): OrderParameterField {
        this.description = description;
        return this;
    }

    getDescription(): string {
        return this.description;
    }

    setName(value: string, appendCode?: boolean): OrderParameterField {
        this.name = (appendCode) ? value + '_' + this.code.toString() : value;
        return this;
    }

    getName(): string {
        return this.name;
    }

    setValue(value: string | string[]): OrderParameterField {
        this.value = value;
        return this;
    }

    getValue(): string | string[] {
        return this.value;
    }

    setCode(value: number): OrderParameterField {
        this.code = value;
        return this;
    }

    getCode(): number {
        return this.code;
    }

    setType(value: string): OrderParameterField {
        this.type = value;
        return this;
    }

    getType(): string {
        return this.type;
    }

    setStatic(): OrderParameterField {
        this.static = true;
        this.type = 'static';
        return this;
    }

    isStatic(): boolean {
        return this.static;
    }

    setFreshValue(value: string): OrderParameterField {
        this.freshvalue = value;
        return this;
    }

    getFreshValue(): string {
        return this.freshvalue;
    }

}

export class OrderFieldsHelper {

    private _fields = [
        { key: 'levelfo', value: 'pp_rendeles' },                               // Aktuális levélfő
        { key: 'cim', value: 'pp_rendeles' },                                   // Megnevezés *
        { key: 'anyagle', value: 'pp_rendeles' },                               // Anyagleadás dátum *
        { key: 'anyagleido', value: 'pp_rendeles' },                            // Anyagleadás * / Megjegyzés / óra / napszak
        { key: 'szallitas', value: 'pp_rendeles' },                             // Határidő *
        { key: 'szallido', value: 'pp_rendeles' },                              // Határidő * / Megjegyzés / óra / napszak
        { key: 'po', value: 'pp_rendeles_reszletek' },                          // Vevői rendeléskód (PO)
    ];

    private _dynFields = [
        { key: '01meret', value: 'pp_rendeles_parameterek' },
        { key: '02terjedelem', value: 'pp_rendeles_parameterek' },
        { key: '03pld', value: 'pp_rendeles_parameterek' },
        { key: '04hozott', value: 'pp_rendeles_parameterek' },
        { key: '05tag', value: 'pp_rendeles_parameterek' },
        { key: '06koteszet', value: 'pp_rendeles_parameterek' }
    ];

    private _dynFieldTypes = {
        '01meret': 'text',
        '02terjedelem': 'text',
        '03pld': 'text',
        '04hozott': 'text',
        '05tag': 'text',
        '06koteszet': 'text'
    };

    getParamContainerName(key: string): string {
        try {
            return this._fields.filter(r => r.key === key)[0].value;
        } catch (e) {

            // console.log('Key (' + key + ') not assignable to standard printportal order fields, trying to get dynamic field container');
            const c = this._dynFields.filter(r => r.key === key);
            if (c.length === 0) {
                let r;
                this._dynFields.forEach((e) => {
                    if (key.indexOf(e.key)) {
                        r = e.value;
                    }
                });

                if (r) {
                    return r;
                } else {
                    throw Error('FATAL: FormControl name ' + key + ' does not exist in the parameters dictionary');
                }
            } else {
                return c[0].value;
            }
        }
    }

    getDynFieldType(key: string): string {

        let t;
        Object.keys(this._dynFieldTypes).forEach((e) => {
            if (e === key || key.indexOf(e) > -1) {
                t = this._dynFieldTypes[e];
            }
        });

        return t;
    }

}