import { Entities } from "@scrollmax/core-nextgen";
import { Nev } from "./nev.entity";

export class Partner extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\Partner';

    code: number;

    company: string;

    city?: string;

    street?: string;

    zip?: string;

    tel?: string;

    fax?: string;
  
    varos?: string;

    utca?: string;

    isz?: string;

    adoszam?: string;

    ugyintezo?: number;

    szamlaemail?: string;    

    ugyvezeto?: string;

    // székhely egy hosszú string, ide is címet lehet beírni, ami a számlára rákerül. (by VG)    
    szekhely?: string;

    // Nagyon hosszú string, amit nem tudunk mire jó.. 
    telephely?: string;

    // neveket itt soroljuk fel, ha esetenként kellenének
    nevek?: Nev[] = [];

    /**
     * Felülírt id lekérdezés, általában a scroll tábláknak más az id mezője (nem mindegyiknek)
     * 
     * @returns 
     */
    override getId(): number {
        return this.getKod();
    }

    /**
     * Alias a code mezőhöz, mivel minden más entitásban ezt használjuk.
     * 
     * @returns 
     */
    getKod(): number {
        return this.code;
    }

    /**
     * Partner kód
     * 
     * @returns 
     */
    getCode(): number {
        return this.code;
    }

    /**
     * Alias a code mezőhöz, mivel minden más entitásban ezt használjuk
     * 
     * @param kod 
     * @returns 
     */
    setKod(kod: number): Partner {
        return this.setCode(kod);
    }

    /**
     * Partner kód setelése
     * 
     * @param code 
     * @returns 
     */
    setCode(code: number): Partner {
        this.code = code;
        return this;
    }

    /**
     * Alias a cégnévhez
     * 
     * @returns 
     */
    getCegnev(): string {
        return this.getCompany();
    }

    /**
     * Cégnév
     * 
     * @returns 
     */
    getCompany(): string {
        return this.company;
    }

    /**
     * Alias a cégnév mezőhöz
     * 
     * @param cegnev 
     * @returns 
     */
    setCegnev(cegnev: string): Partner {
        return this.setCompany(cegnev);
    }

    /**
     * Cégnév setelése
     * 
     * @param company 
     * @returns 
     */
    setCompany(company: string): Partner {
        this.company = company;
        return this;
    }

    /**
     * Székhely város
     * 
     * @returns 
     */
    getSzekhelyVaros(): string | undefined {
        return this.city;
    }

    /**
     * Székhely város setelése
     * 
     * @param varos 
     * @returns 
     */
    setSzekhelyVaros(varos: string): Partner {
        this.city = varos;
        return this;
    }

    /**
     * Székhely utca
     * 
     * @returns 
     */
    getSzekhelyUtca(): string | undefined {
        return this.street;
    }

    /**
     * Székhely utca setelése
     * 
     * @param utca 
     * @returns 
     */
    setSzekhelyUtca(utca: string): Partner {
        this.street = utca;
        return this;
    }
 
    /**
     * Székhely irányítószám
     * 
     * @returns 
     */
    getSzekhelyIrszam(): string | undefined {
        return this.zip;
    }

    /**
     * Székhely irányítószám setelése
     * 
     * @param irsz 
     * @returns 
     */
    setSzekhelyIrszam(irsz: string): Partner {
        this.zip = irsz;
        return this;
    }

    /**
     * Számlázási cím város
     * 
     * @returns 
     */
    getSzamlaVaros(): string | undefined {
        return this.varos;
    }

    /**
     * Számlázási cím város setelése
     * 
     * @param varos 
     * @returns 
     */
    setSzamlaVaros(varos: string): Partner {
        this.varos = varos;
        return this;
    }

    /**
     * Számlázási cím utca
     * 
     * @returns 
     */
    getSzamlaUtca(): string | undefined {
        return this.utca;
    }

    /**
     * Számlázási cím utca setelése
     * 
     * @param utca 
     * @returns 
     */
    setSzamlaUtca(utca: string): Partner {
        this.utca = utca;
        return this;
    }

    /**
     * Számlázási cím irányítószám
     * 
     * @returns 
     */
    getSzamlaIrszam(): string | undefined {
        return this.isz;
    }

    /**
     * Számlázási cím irányítószám setelése
     * 
     * @param irsz 
     * @returns 
     */
    setSzamlaIrszam(irsz: string): Partner {
        this.isz = irsz;
        return this;
    }

    /**
     * Telefon
     * 
     * @returns 
     */
    getTel(): string | undefined {
        return this.tel;
    }    

    /**
     * Telefon setelése
     * 
     * @param tel 
     * @returns 
     */
    setTel(tel: string): Partner {
        this.tel = tel;
        return this;
    }

    /**
     * Fax
     * 
     * @returns 
     */
    getFax(): string | undefined {
        return this.fax;
    }

    /**
     * Fax setelése
     * 
     * @param fax 
     * @returns 
     */
    setFax(fax: string): Partner {
        this.fax = fax;
        return this;
    }

    /**
     * Adószám
     * 
     * @returns 
     */
    getAdoszam(): string | undefined {
        return this.adoszam;
    }

    /**
     * Adószám setelése
     * 
     * @param adoszam 
     * @returns 
     */
    setAdoszam(adoszam: string): Partner {
        this.adoszam = adoszam;
        return this;
    }

    /**
     * Ügyintéző kódja
     * 
     * @TODO felteszem ebből valamilyen (név) objektum lesz majd
     * @returns 
     */
    getUgyintezo(): number | undefined {
        return this.ugyintezo;
    }

    /**
     * Ügyintéző setelése
     * 
     * @param kod 
     * @returns 
     */
    setUgyintezo(kod: number): Partner {
        this.ugyintezo = kod;
        return this;
    }

    /**
     * Számla e-mail
     * 
     * @returns 
     */
    getSzamlaEmail(): string | undefined {
        return this.szamlaemail;
    }

    /**
     * Számla e-mail setelése
     * 
     * @param email 
     * @returns 
     */
    setSzamlaEmail(email: string): Partner {
        this.szamlaemail = email;
        return this;
    }

    /**
     * Telephely 23. nekifutás
     * 
     * @todo (RG) az is egy hosszú string, valami 23. címmel?
     * @todo (VG) Igen az egy még hosszabb, de most nem találtam hol látni kívülről.
     * @todo tehát nem tudjuk mi a lófasz is ez...
     * 
     * @returns 
     */
    getTelephely(): string | undefined {
        return this.telephely;
    }

    /**
     * Telephely setelése
     * 
     * @todo (RG) az is egy hosszú string, valami 23. címmel?
     * @todo (VG) Igen az egy még hosszabb, de most nem találtam hol látni kívülről.
     * @todo tehát nem tudjuk mi a lófasz is ez...
     * 
     * @param telephely 
     * @returns 
     */
    setTelephely(telephely: string): Partner {
        this.telephely = telephely;
        return this;
    }

    /**
     * Teljesen egyedi székhely visszaadása string formátumban
     * 
     * @returns 
     */    
    getSzekhely(): string | undefined {
        return this.szekhely;
    }

    /**
     * Székhely setelése
     * 
     * A székhely egy hosszú string, ide is címet lehet beírni, ami a számlára rákerül. (by VG)
     * 
     * @param szekhely 
     * @returns 
     */
     setSzekhely(szekhely: string): Partner {
        this.szekhely = szekhely;
        return this;
    }

    setNevek(nevek: Nev[]): Partner {
        this.nevek = nevek;
        return this;
    }

    getNevek(): Nev[] | undefined {
        return this.nevek;
    }

    getNevekCount(): number {
        return this.nevek?.length ?? 0;
    }

    /**
     * @inheritdoc
     */
    override __toString(): string {
        return this.getCegnev();
    }

    /**
     * Validáljuk az aktuális entitásunkat, amelyben a cégnevet az adószámot és a székhelyadatokat 
     * írjuk elő kötelezően. Ezen adatok hiányában az entitásunk invalid lesz.
     * 
     * @param entity 
     */
    override validate(entity?: Partner): boolean {

        let a = entity ?? this;

        if (a !== undefined && a !== null && a) {
            return (a.adoszam?.length > 0 && a.company?.length > 0 && a.city?.length > 0 && a.street?.length > 0 && a.zip?.length > 0)
        } else {
            return false;
        }

    }

}
