import { Entities, MetaArray } from "@scrollmax/core-nextgen";

export class SpSoftproof extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpSoftproof';

    nemok?: string;

    ok?: number;
    
    osszesoldal?: number;

    ppsp_kod: number;

    ppsp_taska: number;
 
    ppsp_nosimulateoverprint = false;
 
    ppsp_blackpointcompensation = false;

    ppsp_fuzet = false;

    ppsp_imgformat: string;
 
    ppsp_pagebox: string;

    ppsp_colorspace: string;

    ppsp_jpegformat: string;

    ppsp_compression: string;

    ppsp_resolution: string;

    ppsp_smoothing: string;

    ppsp_allapot: number;

    ppsp_uzenet: string;

    ppsp_cim: string;

    ppsp_nevkod: number;

    ppsp_hatarido: string;

    ppsp_rrequestid: string;

    ppsp_fajlnev: string;

    ppsp_hash: string;

    ppsp_x_ins_user: number;
 
    ppsp_x_ins_date: string;

    ppsp_x_mod_date: string;

    ppsp_x_mod_nev: string;

    feltolto: string;

    oldallista: unknown[] = [];

    getPages(): unknown[] {
        return this.oldallista;
    }

    getHash(): string {
        return this.ppsp_hash;
    }

    getFileNev(): string | null {
        if (this.ppsp_fajlnev.split('\\').length > 0)
            return this.ppsp_fajlnev.split('\\')[this.ppsp_fajlnev.split('\\').length - 1];

        return 'softproof.pdf';
    }


    override __toString(): string {
        throw new Error("Method not implemented.");
    }

    override validate(entity?: SpSoftproof): boolean {
        throw new Error("Method not implemented.");
    }

}