import { Entities } from "@scrollmax/core-nextgen";

import { Diszpo, ScrollFile, Szallito } from "../../scroll";

import { SpSzamla } from "./sp-szamla.entity";
import { SpSoftproof } from "./sp-softproof.entity";
import { SpTaskaFile } from "./sp-taska-file.entity";
import { AllapotEnumerations, AllapotKodToType, TaskaAllapotToType } from "../../../models/printportal";

export class SpTaskaDetails extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpTaskaDetails';

    szam: number;

    taskaszam: string;

    partner: number;

    partnernev: string;

    cim: string;

    taska_allapot: string;

    elotaska: boolean;

    rendszam: number;

    anyagle: string;

    szallitas: string;

    kontakt_nev: string;
    kontakt_kod: number;

    imprimalo_nev: string;
    imprimalo_kod: number;

    anyagle_nev: string;
    anyagle_kod: number;

    gepindul_nev: string;
    gepindul_kod: number;
    gepindulasok: unknown[] = [];

    anyagle_hatarido: string;
    anyagle_allapot = 0;

    imprimalas_hatarido: string;
    imprimalas_allapot = 0;

    elotaska_szam: number;

    kelltdnyilatkozat: boolean;
    termekdijkateg: string;

    sp_fajlok: SpTaskaFile[] = [];

    sp_diszpok: Diszpo[] = [];

    sp_szallitok: Szallito[] = [];

    sp_szamlak: SpSzamla[] = [];

    sp_megrendelesfajlok: ScrollFile[] = [];

    sp_termekdijfajlok: ScrollFile[] = [];

    sp_softproofok: SpSoftproof[] = [];

    anyagleadas_jog: boolean;

    imprimalas_jog: boolean;

    taska_jog: boolean;

    termekdijkateg_kod?: number;

    isbn?: string;
    
    cikkszam?: string;

    getISBN(): string|null {
        return this.isbn;
    }

    getCikkszam(): string|null {
        return this.cikkszam;
    }

    /**
     * FONTOS!! - ez a táskaszám, amivel dolgozunk a backenden! 
     * referencia érték és természetesen nem ID-nek hívják még véletlenül sem
     * 
     * @returns 
     */
    getUniqueId(): number {
        return this.szam;
    }

    /**
     * Az ügyfél által is látott táskaszám
     * @returns
     */
    getTaskaszam(): string {
        return this.taskaszam;
    }

    /**
     * A táska előtáska státuszban van-e
     * @returns 
     */
    isElotaska(): boolean {
        return this.elotaska;
    }

    /**
     * Termék megnevezése
     * @returns 
     */
    getProductName(): string {
        return this.cim;
    }

    /**
     * Termék rendelésszáma, ha van
     * @returns 
     */
    getProductOrderNumber(): number {
        return this.rendszam;
    }

    /**
     * Szállítási dátum
     * @returns 
     */
    getProductDeliveryDate(): string {
        return this.szallitas;
    }

    /**
     * Partner megnevezése
     * @returns 
     */
    getPartnerName(): string {
        return this.partnernev;
    }

    /**
     * Kontakt kód
     * @returns 
     */
    getKontaktKod(): number {
        return this.kontakt_kod;
    }

    /**
     * Kontakt név
     * @returns 
     */
    getKontaktNev(): string|null {
        return this.kontakt_nev;
    }

    /**
     * Anyagleadó neve
     * @returns 
     */
    getAnyagleadasNev(): string {
        return this.anyagle_nev;
    }

    /**
     * Anyagleadó kódja (id vagy kód a nevek táblában)
     * @returns 
     */
    getAnyagleadasKod(): number {
        return this.anyagle_kod;
    }

    /**
     * Imprimáló neve
     * @returns 
     */    
    getImprimaloNev(): string {
        return this.imprimalo_nev;
    }

    /**
     * Imprimáló név kódja (id vagy kód a nevek táblában)
     * @returns 
     */    
    getImprimaloKod(): number {
        return this.imprimalo_kod;
    }

    /**
     * Gépinduló neve
     * @returns 
     */     
    getGepindulasNev(): string {
        return this.gepindul_nev;
    }

    /**
     * Gépinduló név kódja (id vagy kód a nevek táblában)
     * @returns 
     */     
    getGepindulasKod(): number {
        return this.gepindul_kod;
    }

    /**
     * Táska aktuális állapota - FONTOS - eleve stringben kapjuk vissza, de a PP fordító motorja miatt 
     * erre külön logika van felépítve, tehát nem ezt használjuk hanem ezt csak átküldjük a fordító motoron!
     * 
     * @returns 
     */
    getTaskaAllapot(): string {
        return this.taska_allapot;
    }

    /**
     * Általános állapotokat adunk vissza. Jelenleg a frontenden fordítjuk át ezeket színre 
     * és labelre termséezetesen
     * 
     * AKTIV = 0,
     * FOLYAMATBAN = 1,
     * PROBLEMA = 2,
     * RENDBEN = 3,
     * TOROLT = 4,
     * NEM_SZUKSEGES = 5
     */
    getAnyagleadasAllapot(): number {
        return this.anyagle_allapot;
    }

    /**
     * Anyagleadás határideje
     * @returns 
     */
    getAnyagleadasHatarido(): string {
        return this.anyagle_hatarido;
    }

    /**
     * Általános állapotokat adunk vissza. Jelenleg a frontenden fordítjuk át ezeket színre 
     * és labelre termséezetesen
     * 
     * AKTIV = 0,
     * FOLYAMATBAN = 1,
     * PROBLEMA = 2,
     * RENDBEN = 3,
     * TOROLT = 4,
     * NEM_SZUKSEGES = 5
     */
    getImprimalasAllapot(): number {
        return this.imprimalas_allapot;
    }

    /**
     * Imprimálás határideje
     * @returns 
     */
    getImprimalasHatarido(): string {
        return this.imprimalas_hatarido;
    }

    /**
     * Az imprimálási információk (dátum pl.) megjeleníthetők-e. Jelenleg ha 
     * nem szükséges vagy rendben van akkor false-t adunk vissza
     * 
     * @returns 
     */
    isImprimalasVisible(): boolean {
        return (this.imprimalas_allapot !== 5 && this.imprimalas_allapot !== 3);
    }

    /**
     * Ha van imprimálás task nyitva, akkor ez alapján tudunk logikát építeni
     * @returns 
     */    
    isAnyagleadasTaskActive(): boolean {
        return (this.anyagle_allapot !== 5 && this.anyagle_allapot !== 3)
    }

    /**
     * Ha van imprimálás task nyitva, akkor ez alapján tudunk logikát építeni
     * @returns 
     */    
    isImprimalasTaskActive(): boolean {
        return this.isImprimalasVisible();
    }

    /**
     * Ha kell termékdíjnyilatkozat, akkor ez alapján tudunk logikát építeni
     * @returns 
     */
    isStatementTaskActive(): boolean {
        return this.kelltdnyilatkozat;
    }

    /**
     * Termékdíj kategória
     * @returns 
     */
    getStatementCategory(): string {
        return this.termekdijkateg;
    }

    /**
     * Termékdíj kategória azonosítója
     * @returns 
     */    
    getStatementCategoryId(): number|null {
        return this.termekdijkateg_kod;
    }

    getFiles(): SpTaskaFile[] {
        return this.sp_fajlok;
    }

    hasFiles(): boolean {
        return (this.sp_fajlok.length > 0);
    }

    getDiszpoList(): Diszpo[] {
        return this.sp_diszpok;
    }

    hasDispoList(): boolean {
        return (this.sp_diszpok.length > 0);
    }

    getSzallitoList(): Szallito[] {
        return this.sp_szallitok;
    }

    hasSzallitoList(): boolean {
        return (this.sp_szallitok.length > 0);
    }

    getSzamlaList(): SpSzamla[] {
        return this.sp_szamlak;
    }

    hasSzamlaList(): boolean {
        return (this.sp_szamlak.length > 0);
    }

    getSoftproofList(): SpSoftproof[] {
        return this.sp_softproofok;
    }

    hasSoftproofList(): boolean {
        return (this.sp_softproofok.length > 0);
    }

    getOrderFiles(): ScrollFile[] {
        return this.sp_megrendelesfajlok;
    }

    hasOrderFiles(): boolean {
        return (this.sp_megrendelesfajlok.length > 0);
    }

    getStatementFiles(): ScrollFile[] {
        return this.sp_termekdijfajlok;
    }

    hasStatementFiles(): boolean {
        return (this.sp_termekdijfajlok.length > 0);
    }

    isPermission(key: string): boolean {

        switch(key) {
            case 'anyagleadas':
                return this.anyagleadas_jog;
                break;
            case 'imprimalas':
                return this.imprimalas_jog;
                break;
            case 'taska':
                return this.taska_jog;
                break;
        }

        return false;
    }

    isAnyagleadasAllowed(): boolean {
        return this.anyagleadas_jog;
    }

    isImprimalasAllowed(): boolean {
        return this.imprimalas_jog;
    }

    isTaskaAllowed(): boolean {
        return this.taska_jog;
    }

    getGepindulasokList(): unknown[] {
        return this.gepindulasok;
    }

    hasGepindulasokList(): boolean {
        return (this.gepindulasok.length > 0);
    }

    //
    // Helyben csinálunk helpereket az állapotok badge és text színeire, mert nagyon sok helyen kell használni őket
    //

    /**
     * Fő táska állapot fordítási tag
     * @returns 
     */
    getTaskaAllapotLabel(): string {
        return AllapotEnumerations.GetEnumerationArray().find(l => l.__toString() === TaskaAllapotToType.GetAllapotType(this.taska_allapot)?.toString())?.getLabel ?? 'enum_label_no_data';
    }

    /**
     * Anyagleadás badge color
     * @returns
     */
    getAnyagleadasBadgeColor(): string {
        return AllapotKodToType.GetBadgeColor(AllapotKodToType.GetType(this.anyagle_allapot));
    }

    /**
     * Anyagleadás text color
     * @returns 
     */
    getAnyagleadasTextColor(): string {
        return AllapotKodToType.GetTextColor(AllapotKodToType.GetType(this.anyagle_allapot));
    }

    /**
     * Anyagleadás fordítási tag
     * @returns 
     */
    getAnyagleadasAllapotKodLabel(): string {
        return AllapotEnumerations.GetEnumerationArray().find(l => l.__toString() === this.anyagle_allapot?.toString())?.getLabel ?? 'enum_label_no_data';
    }

    /**
     * Imprimálás (jóváhagyás) badge color
     * @returns 
     */
    getJovahagyasBadgeColor(): string {
        return AllapotKodToType.GetBadgeColor(AllapotKodToType.GetType(this.imprimalas_allapot)); 
    }

    /**
     * Imprimálás (jóváhagyás) text color
     * @returns 
     */
    getJovahagyasTextColor(): string {
        return AllapotKodToType.GetTextColor(AllapotKodToType.GetType(this.imprimalas_allapot));
    }

    /**
     * Imprimálás (jóváhagyás) fordítási tag
     * @returns 
     */
    getJovahagyasAllapotKodLabel(): string {
        return AllapotEnumerations.GetEnumerationArray().find(l => l.__toString() === this.imprimalas_allapot?.toString())?.getLabel ?? 'enum_label_no_data';
    }

    getJovahagyasAllapotVisual() {

        if ((this.imprimalas_hatarido !== null) && (this.imprimalas_allapot !== 5 && this.imprimalas_allapot !== 3 && this.imprimalas_allapot !== 10 && this.imprimalas_allapot > -1)) {
            return 1;
        } else if (this.imprimalas_allapot === 10) {
            return 2;
        } else if (this.imprimalas_allapot === 5) {
            return 3;
        } else if (this.imprimalas_allapot === 3) {
            return 4;
        }

        return 0;

    }


    override __toString(): string {
        throw new Error("Method not implemented.");
    }

    override validate(entity?: SpTaskaDetails): boolean {
        throw new Error("Method not implemented.");
    }

}