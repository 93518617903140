import { Entities } from "@scrollmax/core-nextgen";

export class SpDiszpo extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\SpDiszpo';

    override id: number;

    datum: string;

    taskakod: number;

    pld: number;

    ceg: string;

    varos: string;

    utca: string;

    isz: string;

    taskaszam: string;

    cim: string;

    rendszam: string;

    okdb: number;

    sajat: boolean;

    nevkod: number;

    nev: number;

    pp_jog_taskak: boolean;

    isOwn(): boolean {
        return this.sajat;
    }

    isSzallito(): boolean {
        return (this.pld === this.okdb);
    }

    override __toString(): string {
        throw new Error("Method not implemented.");
    }

    override validate(entity?: SpDiszpo): boolean {
        throw new Error("Method not implemented.");
    }

}