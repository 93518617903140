import { Entities } from "@scrollmax/core-nextgen";

export class Szallito extends Entities.CoreEntity {

    static override kind = 'ScrollMax\\Scroll\\Entity\\Szallito';

    /** 
     * Nem fontos nekünk, nem használjuk - placeholder 
     * @var number 
     */
    override id: number;

    /**
     * @var number
     */
    szallitoszam: number;
    
    /**
     * @var string
     */
    kelt: string;

    /**
     * @var string
     */
    ceg: string;

    /**
     * @var string
     */
    isz: string;

    /**
     * @var string
     */
    utca: string;

    /**
     * @var string
     */
    varos: string;

    /**
     * @var string
     */
    nev: string;

    /**
     * @var number
     */
    db: number;
       
    /**
     * @var string
     */
    me: string;

    /**
     * Szállító dátum
     * @returns 
     */
    getDate(): string {
        return this.kelt;
    }

    /**
     * Szállítón szereplő termék neve
     * @returns 
     */
    getProductName(): string {
        return this.nev;
    }

    /**
     * Formázott cím string: {irsz} {város}, {utca (hrsz)}
     * @returns
     */
    getAddressString(): string {
        return [[this.isz, this.varos].join(' '), this.utca].join(', ');
    }

    /**
     * A mennyiség mértékegysége
     * @returns 
     */
    getMeasure(): string {
        return this.me;
    }

    /**
     * Szállított mennyiség
     * @returns 
     */
    getQty(): number {
        return this.db;
    }

    /**
     * Szállított mennyiség formázva: {qty} {meaasure}
     * @returns 
     */
    getQtyString(): string {
        return [this.getQty(), this.getMeasure()].join(' ');
    }

    override __toString(): string {
        throw new Error("Method not implemented.");
    }
    
    override validate(entity?: Szallito): boolean {
        throw new Error("Method not implemented.");
    }
}