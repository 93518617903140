import { environment } from "../../../../environments/environment";

export interface IScrollParamCollection {
    
    items: ScrollParam[];

    add(value: IScrollParam): ScrollParamCollection;
    get(opcionev: string): ScrollParam;
    has(opcionev: string): boolean;
}

export interface IScrollParam {
    opcionev: string;
    ertek?: string | unknown;
}
/**
 * A ScrollParams értékeket tároljuk ebben a collectionban, minden kényelmi 
 * metódust itt fogunk megvalósítani
 */
export class ScrollParamCollection implements IScrollParamCollection {
    
    items: ScrollParam[] = [];

    add(value: ScrollParam): ScrollParamCollection {
        this.items.push(value);
        return this;
    }

    get(opcionev: string): ScrollParam {
        
        if (this.has(opcionev))
            return this.items.find(i => i.opcionev === environment.scrollparams.prefix + opcionev.toUpperCase());

        if (environment.debug)
            console.warn('[ScrollParams] Option not found', opcionev, this.items);

        return new ScrollParam();
    }

    has(opcionev: string): boolean {
        if (this.items.find(i => i.opcionev === environment.scrollparams.prefix + opcionev.toUpperCase()))
            return true;

        return false;
    }
}

export class ScrollParam {
    
    opcionev: string;
    
    ertek?: string | unknown;

    setKey(value: string): ScrollParam {
        this.opcionev = value;
        return this;
    }

    getKey(): string {
        return this.opcionev;
    }

    setValue(value: string): ScrollParam {
        this.ertek = value;
        return this;
    }

    /**
     * String formátumban visszaadjuk az értéket
     * @returns 
     */
    get(defaultValue: string = undefined): string {
        
        if (this.ertek)
            return this.ertek as string;

        return defaultValue;
    }

    /**
     * Vizsgáljuk, hogy az érték valóban érték -e
     * @returns 
     */
    has(): boolean {
        return (this.ertek && this.ertek != undefined && this.ertek != null && this.ertek != '') ? true : false;
    }

    eq(value: string): boolean {
        return this.ertek === value;
    }

    /**
     * Vizsgáljuk, hogy az érték valamilyen boolean érték -e
     * @param defaultValue
     * @returns 
     */
    is(defaultValue: boolean): boolean {
        
        if (!this.has())
            return defaultValue;

        if (this.ertek === true || this.ertek === '1' || this.get().toLowerCase() === 'yes' || this.get().toLowerCase() === 'on')
            return true;

        if (this.ertek === false || this.ertek === '0' || this.get().toLowerCase() === 'no' || this.get().toLowerCase() === 'off')
            return false;

        return defaultValue;
    }

}